import React from "react";
import FlightSearchDetailsComponent from "../../components/flights/FlightSearchDetailsComponent";
import { connect } from "react-redux";
import { getFlightSearchResults, getKafilaFlightSearchResults, sendItinerariesEmail, getFlightFareRuleDetails, getKafilaFlightFareRuleDetails, filterFlightResults, getFlightPriceDetails, reInitializeFlightPriceDetails,sortFlightResults,getTripJackFlightSearchResults,getTripJackFlightFareRuleDetails,getAirIQFlightSearchResults, getAirIQFlightFareRuleDetails, getGoflyFlightSearchResults, getGoflyFlightFareRuleDetails, geteflyFlightSearchResults, geteflyFlightFareRuleDetails, getSatkarFlightFareRuleDetails, getSatkarFlightSearchResults, getRiyaFlightSearchResults, getRiyaFlightFareRuleDetails} from "../../actions/flight/flightSearchDetailsAction";
import * as flightHelper from '../../shared/flightHelper';
import { dateFormat, currencyFormat, remvoveArrayElement } from '../../shared/commonHelper';
import { myAccountAction } from '../../actions/agentAction';
import { isFilterChecked,scroll_position,isFilterCheckedInbound } from "../../actions/shared/sharedAction";
import {kafilaSeatState} from '../../actions/flight/flightBookTicketAction'

class FlightSearchDetailsContainer extends React.Component {
    constructor() {
        super()
        this.state = {
            sendMailerror: null,
            sendMailsuccess: null
        }
    }

    //send mail
    sendItinerariesMessage = (sendMailValues,journeyType) => {
        var finalMessage = '';
        if (sendMailValues.length === 0) {
            this.setState({ sendMailerror: 'Please select flight(s)' ,sendMailsuccess: null});
        } else if (sendMailValues.length > 0) {
            //Get passenger count
            if(this.props.searchQuery.journey_url === 'multi-city')
            {
                let multiCitySegments = this.props.searchQuery.segments;
                finalMessage = 'Here is flight option for '+this.props.searchQuery.journey_type_name;
                multiCitySegments.map((itemResult, keyResult) => {
                    finalMessage += ' "'+itemResult.originCityFullName+'(' + itemResult.originAirportCode + ') - ' + itemResult.destinationCityFullName + '(' + itemResult.destinationAirportCode + ')",'+dateFormat(itemResult.preferred_arrival_time, 'FDAY, DD MMM YYYY')+'" ';
                    return true;
                });
                
            }else{
                // Get origion city 
                var origionCity = this.props.searchQuery.originCityFullName;
                var origionCityCode = this.props.searchQuery.originAirportCode;
                //Get destination city
                var destinationCity = this.props.searchQuery.destinationCityFullName;
                var destinationCityCode = this.props.searchQuery.destinationAirportCode;
                finalMessage = 'Here is flight option for '+this.props.searchQuery.journey_type_name+' "'+origionCity+'(' + origionCityCode + ') - ' + destinationCity + '(' + destinationCityCode + ')",'+dateFormat(this.props.searchQuery.segments[0].preferred_arrival_time, 'FDAY, DD MMM YYYY');
            }
            if(this.props.searchQuery.journey_url === 'round-trip')
            {
                finalMessage += ' to '+ dateFormat(this.props.searchQuery.segments[1].preferred_arrival_time, 'FDAY, DD MMM YYYY')+'%0a'
            }else{
                finalMessage +='%0a %0a';
            }
            if (sendMailValues.length > 0) {
                let count = 1;
                //get data from result array
                for(let i = 0;i<this.props.searchResult.results.length;i++){
                    let boundTypeResult = this.props.searchResult.results[i];
                    /* eslint-disable-next-line */
                    boundTypeResult.map((itemResult, keyResult) => {
                        if (sendMailValues.indexOf(itemResult.result_index) > -1) {
                            let flightDuration = flightHelper.getDuration(itemResult.segments[0]);
                            
                            itemResult.segments.map((segment, segmentIndex) => {
                                segment.map((stopSegment, stopSegmentIndex) => {
                                    if(stopSegmentIndex > 0)
                                    {
                                        finalMessage = finalMessage+'%0a   '+stopSegment.airline.airline_name+'('+stopSegment.airline.airline_code + ' '+ stopSegment.airline.flight_number+')%0a';
                                    }else{
                                        finalMessage = finalMessage+count+'. '+stopSegment.airline.airline_name+'('+stopSegment.airline.airline_code + ' '+ stopSegment.airline.flight_number+')%0a';
                                        count = count + 1;
                                    }
                                    finalMessage = finalMessage+'   Depart from "'+stopSegment.origin.airport.city_name+' at '+dateFormat(stopSegment.origin.dep_time, 'FDAY, DD MMM YYYY h:m')+'", Arrive '+stopSegment.destination.airport.city_name+' at '+dateFormat(stopSegment.destination.arr_time, 'FDAY, DD MMM YYYY h:m')+',';
                                    return true;
                                })
                                return true;
                            })
                            finalMessage = finalMessage+'  Duration:'+flightDuration+', Price ₹'+currencyFormat(itemResult.fare.published_fare)+'.%0a %0a';
                        }
                        return true;
                    })
                }
                var final_text	 = `https://api.whatsapp.com/send?phone=&text=${finalMessage}`;
                window.open(final_text, '_blank');
                
            }
        }
    }

    //send mail
    sendItinerariesMail = (email, sendMailValues, includeFare) => {
        let isMail = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (sendMailValues.length === 0) {
            this.setState({ sendMailerror: 'Please select flight(s)' ,sendMailsuccess: null});
        } else if (!email) {
            this.setState({ sendMailerror: 'Please enter email address',sendMailsuccess: null });
        } else if (email && sendMailValues.length > 0) {
            if (isMail.test(email)) {
                // Get origion city
                var origionCity = this.props.searchQuery.segments[0].originCityName;
                var origionCityCode = this.props.searchQuery.segments[0].origin;
                //Get destination city
                var destinationCity = this.props.searchQuery.segments[this.props.searchQuery.segments.length-1].destinationCityName;
                var destinationCityCode = this.props.searchQuery.segments[this.props.searchQuery.segments.length-1].destination;
                //Get passenger count
                let passengerCount = this.props.searchQuery.adult_count + ' Adult(s)' + ((this.props.searchQuery.child_count > 0) ? ' ,' + this.props.searchQuery.child_count + ' Child(s)' : '') + ((this.props.searchQuery.infant_count > 0) ? ' ,' + this.props.searchQuery.infant_count + ' Infant(s)' : '');
                if (sendMailValues.length > 0) {
                    let itineraries = [];
                    //get data from result array
                    for(let i = 0;i<this.props.searchResult.results.length;i++){
                        let boundTypeResult = this.props.searchResult.results[i];
                        let itinerariesboundTypeArray = [];
                        boundTypeResult.map((itemResult, keyResult) => {
                            if (sendMailValues.indexOf(itemResult.result_index) > -1) {
                                let flightDuration = flightHelper.getDuration(itemResult.segments[0]);
                                let flightSegments = [];
                                itemResult.segments.map((segment, segmentIndex) => {
                                    segment.map((stopSegment, stopSegmentIndex) => {
                                        let flightSegment = {
                                            "flight_code" : stopSegment.airline.airline_code,
                                            "flight_name": stopSegment.airline.airline_code + " " + stopSegment.airline.flight_number,
                                            "depart": stopSegment.origin.airport.city_name,
                                            "depart_time": dateFormat(stopSegment.origin.dep_time, 'FDAY, DD MMM YYYY h:m'),
                                            "arrive": stopSegment.destination.airport.city_name,
                                            "arrive_time": dateFormat(stopSegment.destination.arr_time, 'FDAY, DD MMM YYYY h:m')
                                        }
                                        flightSegments.push(flightSegment);
                                        return true;
                                    })
                                    return true;
                                })
                                let flightDetails = {
                                    "total_flight_time": flightDuration,
                                    "price": "",
                                    "route_info": flightSegments
                                }
                                if(includeFare === true)
                                {
                                    flightDetails.price = currencyFormat(itemResult.fare.published_fare);
                                }
                                itinerariesboundTypeArray.push(flightDetails);
                            }
                            return true;
                        })
                        itineraries.push(itinerariesboundTypeArray);
                    }
                    const finalData = {
                        "email": email,
                        "flight_route": origionCity + '(' + origionCityCode + ') - ' + destinationCity + '(' + destinationCityCode + ')',
                        "flight_time": dateFormat(this.props.searchQuery.segments[0].preferred_arrival_time, 'FDAY, DD MMM YYYY'),
                        "passenger": passengerCount,
                        "itineraries": itineraries
                    }
                    /* on successful mail send*/
                    const successCb = (success) => {
                        this.setState({ sendMailsuccess: success, sendMailerror: null });
                    }
                    /* on error, update the error in the state */
                    const errorCb = ( {status, message, errors}) => {
                        this.setState({ sendMailerror: message, sendMailsuccess: null });
                    }
                    this.props.sendItinerariesEmail(finalData, successCb, errorCb);
                }
            } else {
                this.setState({ sendMailerror: 'Please enter valid email address' ,sendMailsuccess: null});
            }
        }
    }

    //Get flight fare rules details
    flightFareDetails = (traceID, resultIndex, segements, apiSource,uid,airline_remark,airline_code,supplier_airline_remark, flightId,trackId) => {
        const is_kafila_user = localStorage.getItem('is_kafila_user');
        let category_code = this.props.searchQuery && this.props.searchQuery.is_domestic
        if(is_kafila_user === 'Y' && apiSource === 'kafila')
        {
            let kafilaSegment = [{
                "origin": segements[0][0].origin.airport.airport_code,
                "destination":  segements[0][segements[0].length -1].destination.airport.airport_code,
            }]
            let FareRuleDetailsCredential = {"trace_id": traceID, "result_index" : resultIndex, "segments": segements,
            "fare_type":supplier_airline_remark,"airline_code":airline_code,"origin_code":kafilaSegment[0].origin,"destination_code":kafilaSegment[0].destination, "category" : (category_code === "true" ? 1: 2)}
            this.props.getKafilaFlightFareRuleDetails(FareRuleDetailsCredential);
        }
        else if(apiSource === 'omairiq')
        {
            let airIQSegment = [{
                "origin": segements[0][0].origin.airport.airport_code,
                "destination":  segements[0][segements[0].length -1].destination.airport.airport_code,
            }]
            let FareRuleDetailsCredential = {
                "trace_id": traceID,
                "result_index" : resultIndex,
                "segments": airIQSegment,
                "fare_type":supplier_airline_remark,
                "airline_code":airline_code,
                "origin":airIQSegment[0].origin,
                "destination":airIQSegment[0].destination,
                "origin_code":airIQSegment[0].origin,
                "destination_code":airIQSegment[0].destination,
                "category" : (category_code === "true" ? 1: 2)
            }
            this.props.getAirIQFlightFareRuleDetails(FareRuleDetailsCredential);
        }
        else if(apiSource === 'tripjack')
        {
           
            let origin = segements[0][0].origin.airport.airport_code;
            let destination = segements[0][segements[0].length -1].destination.airport.airport_code;

            let FareRuleDetailsCredential = {"uid": uid, "flowType" : "SEARCH", "origin": origin,"destination": destination,"origin_code": origin,"destination_code": destination,"fare_type":supplier_airline_remark,"airline_code":airline_code,"category" : (category_code === "true" ? 1: 2)}

            this.props.getTripJackFlightFareRuleDetails(FareRuleDetailsCredential);
        }
        else if(apiSource === 'gofly')
        {
            let goflySegment = [{
                "origin": segements[0][0].origin.airport.airport_code,
                "destination":  segements[0][segements[0].length -1].destination.airport.airport_code,
            }]
            let FareRuleDetailsCredential = {
                "trace_id": traceID,
                "result_index" : resultIndex,
                "segments": goflySegment,
                "fare_type":supplier_airline_remark,
                "airline_code":airline_code,
                "origin_code":goflySegment[0].origin,
                "destination_code":goflySegment[0].destination,
                "category" : (category_code === "true" ? 1: 2)
            }
            this.props.getGoflyFlightFareRuleDetails(FareRuleDetailsCredential);
        }
        else if(apiSource === 'efly')
        {
            let goflySegment = [{
                "origin": segements[0][0].origin.airport.airport_code,
                "destination":  segements[0][segements[0].length -1].destination.airport.airport_code,
            }]
            let FareRuleDetailsCredential = {
                "trace_id": traceID,
                "result_index" : resultIndex,
                "segments": goflySegment,
                "fare_type":supplier_airline_remark,
                "airline_code":airline_code,
                "origin_code":goflySegment[0].origin,
                "destination_code":goflySegment[0].destination,
                "category" : (category_code === "true" ? 1: 2)
            }
            this.props.geteflyFlightFareRuleDetails(FareRuleDetailsCredential);
        }
        else if(apiSource === 'satkar')
        {
            let satkarSegment = [{
                "origin": segements[0][0].origin.airport.airport_code,
                "destination":  segements[0][segements[0].length -1].destination.airport.airport_code,
            }]
            let FareRuleDetailsCredential = {
                "trace_id": traceID,
                "result_index" : resultIndex,
                "segments": satkarSegment,
                "fare_type":supplier_airline_remark,
                "airline_code":airline_code,
                "origin_code":satkarSegment[0].origin,
                "destination_code":satkarSegment[0].destination,
                "category" : (category_code === "true" ? 1: 2)
            }
            this.props.getSatkarFlightFareRuleDetails(FareRuleDetailsCredential);
        }
        else if(apiSource === 'riya_travel')
            {
                let riyaSegment = [{
                    "origin": segements[0][0].origin.airport.airport_code,
                    "destination":  segements[0][segements[0].length -1].destination.airport.airport_code,
                }]
                let FareRuleDetailsCredential = {
                    "trace_id": traceID,
                    "result_index" : resultIndex,
                    "segments": riyaSegment,
                    "fare_type":supplier_airline_remark,
                    "airline_code":airline_code,
                    "origin_code":riyaSegment[0].origin,
                    "destination_code":riyaSegment[0].destination,
                    "category" : (category_code === "true" ? 1: 2),
                    'flightId': flightId,
                    'trackId': trackId
                }
                this.props.getRiyaFlightFareRuleDetails(FareRuleDetailsCredential);
            }
        else
        {
            let segment = [{
                "origin": segements && segements.length && segements[0].length && segements[0][0] && segements[0][0].origin && segements[0][0].origin.airport && segements[0][0].origin.airport.airport_code,
                "destination": segements && segements.length && segements[0] && segements[0][segements[0].length -1] && segements[0][segements[0].length -1].destination.airport.airport_code,
            }]
            let FareRuleDetailsCredential = {"trace_id": traceID, "result_index" : resultIndex,"fare_type":supplier_airline_remark,
            "airline_code":airline_code,"segments": segment,"origin_code":segment[0].origin,"destination_code":segment[0].destination,"category" : (category_code === "true" ? 1: 2)}
            this.props.getFlightFareRuleDetails(FareRuleDetailsCredential); 
        }          
    }


    //Get flight price details
    flightPriceDetails = (flightData) => {
        this.props.getFlightPriceDetails(flightData);
    }

    componentDidMount() {
        let filterHEight=document.getElementById('adv-filter');
        this.setState({clientHeight:filterHEight && filterHEight.clientHeight})
        this.props.kafilaSeatState([])
        const is_kafila_user = localStorage.getItem('is_kafila_user');
        const setMultiDestinationAlert = (totalResults) => {
           
            let uniqueDestinations = [];
            //let totalResults = this.props.searchResult && this.props.searchResult.results;
            let outboundDestinations = totalResults && totalResults[0] && flightHelper.getAllUniqueDestination(totalResults[0]);
            let inboundDestinations = totalResults && totalResults[1] && flightHelper.getAllUniqueDestination(totalResults[0]);

            if(outboundDestinations && outboundDestinations.length > 1)
            {
                let tmp = [];
                outboundDestinations.forEach(function(data){
                    tmp.push(data[0]);
                });
                uniqueDestinations = remvoveArrayElement(tmp, this.props.searchQuery.segments[0].destination);
            }

            if(inboundDestinations && inboundDestinations.length > 1)
            {
                let tmp = [];
                inboundDestinations.forEach(function(data){
                    tmp.push(data[0]);
                });

                uniqueDestinations = uniqueDestinations.concat(remvoveArrayElement(tmp, this.props.searchQuery.segments[1].destination));
            }
            if(uniqueDestinations.length > 1)
            {
                alert("You will get the results of " + uniqueDestinations.join(", ") + " with the result of destination " + this.props.searchQuery.segments[0].destination);
            }
        }
        if(!this.props.history.location.state.searchAPINo){
            if (this.props.searchQuery.pft === 'REGULAR') {
                this.props.getFlightSearchResults(this.props.searchQuery, setMultiDestinationAlert);
                setTimeout(() => {
                    if (['3'].includes(this.props.searchQuery.journey_type) && this.props.searchQuery.journey_url === 'multi-city' && this.props.searchQuery.is_domestic === 'false') {
                        {
                            this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }

                }, 2000);
            } else if (this.props.searchQuery.pft === 'STUDENT') {
                this.props.getFlightSearchResults(this.props.searchQuery, setMultiDestinationAlert);
                setTimeout(() => {
                    if (['3'].includes(this.props.searchQuery.journey_type) && this.props.searchQuery.journey_url === 'multi-city' && this.props.searchQuery.is_domestic === 'false') {
                        {
                            this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }

                }, 2000);
            } else if (this.props.searchQuery.pft === 'SENIOR_CITIZEN') {
                this.props.getFlightSearchResults(this.props.searchQuery, setMultiDestinationAlert);
                setTimeout(() => {
                    if (['3'].includes(this.props.searchQuery.journey_type) && this.props.searchQuery.journey_url === 'multi-city' && this.props.searchQuery.is_domestic === 'false') {
                        {
                            this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }

                }, 2000);
            }
            setTimeout(() => {
                if(is_kafila_user === 'Y' && ['1', '2','5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' || this.props.searchQuery.pft === 'STUDENT'))
                {
                    if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return')) {
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                             this.props.getKafilaFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }, 1000);    
            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
            if(this.props.searchQuery.pft === 'REGULAR')
            {
                setTimeout(() => {
                    if(['1', '2'].includes(this.props.searchQuery.journey_type))
                        {
                                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }, 2000);    
            }
            else if(['1', '2'].includes(this.props.searchQuery.journey_type))
            {
                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
            }
        }
            setTimeout(() => {
                if (
                    (['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                    this.props.searchQuery.pft === 'REGULAR')) {
                    if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                    } else {
                        if(this.props.searchQuery.is_domestic === 'false'){
                            if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                                if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                    this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                                }
                            }else{
                                this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                             this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                            }
                        }




                        // if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||
                        //  this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return') && (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS")) {
                        //     console.log(306);
                        //     this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                        // }
                        // else{
                        //     console.log(310);
                        // this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                        // }
                    }
                }
            }, 3000);

            setTimeout(() => {
                if (
                    ['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                    this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journeyUrl !== 'advance-search') {
                    if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                    } else {
                        if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return')) {
                        }else{
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                        this.props.geteflyFlightSearchResults(this.props.searchQuery);
                            }
                        }
                    }
                }
            }, 3000);

            setTimeout(() => {
                if (this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search') {
                    if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {

                    }
                    else {
                        if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return')) {
                        }else{
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                        this.props.getAirIQFlightSearchResults(this.props.searchQuery);
                            }
                        }
                    }
                }
            }, 3000);

            setTimeout(() => {
                if (
                    (['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                    this.props.searchQuery.pft === 'REGULAR')) {
                    if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                    } else {
                        if(this.props.searchQuery.is_domestic === 'false'){
                            if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                                if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                    this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                                }
                            }else{
                                this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                             this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                            }
                        }
                    }
                }
            }, 3200);

            setTimeout(() => {
                if(['1', '2','5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' || this.props.searchQuery.pft === 'STUDENT'))
                {
                    if(this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search'){
                       
                            this.props.getRiyaFlightSearchResults(this.props.searchQuery);
                        
                    }
                }
            }, 1000); 

        }
        this.props.myAccountAction();
    }
    render() {
        return <FlightSearchDetailsComponent 
            {...this.props} 
            sendItinerariesMail={this.sendItinerariesMail} 
            sendItinerariesMessage={this.sendItinerariesMessage} 
            flightFareDetails={this.flightFareDetails}
            flightPriceDetails={this.flightPriceDetails}
            {...this.state} 
        />;
       
        
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...state.flightSearchDetailsReducer,
        searchQuery: state.flightSearchReducer.searchQuery,
        productResponse: state.shared.productResponse,
        accountResponse    : state.myAccount,//my account details
        agentProductAccess     : state.shared.agentProductAccess,
        isFilterCheckedStatus : state.shared.isFilterChecked,
        isFilterCheckedStatusInound : state.shared.isFilterCheckedInbound,
        track_scroll_position: state.shared.scroll_position,
        kafilaSeatStates: state.flightBookTicketReducer.kafilaSeatState
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getFlightSearchResults: (searchQuery, callback) => dispatch(getFlightSearchResults(searchQuery, callback)),
        getKafilaFlightSearchResults: (searchQuery, callback) => dispatch(getKafilaFlightSearchResults(searchQuery, callback)),
        getTripJackFlightSearchResults: (searchQuery) => dispatch(getTripJackFlightSearchResults(searchQuery)),
        getAirIQFlightSearchResults: (searchQuery)=> dispatch(getAirIQFlightSearchResults(searchQuery)),
        sendItinerariesEmail: (data, successCb, errorCb) => dispatch(sendItinerariesEmail(data, successCb, errorCb)),
        getFlightFareRuleDetails: (credential) => dispatch(getFlightFareRuleDetails(credential)),
        getKafilaFlightFareRuleDetails: (credential) => dispatch(getKafilaFlightFareRuleDetails(credential)),
        getAirIQFlightFareRuleDetails: (credential) => dispatch(getAirIQFlightFareRuleDetails(credential)),
        getTripJackFlightFareRuleDetails: (credential) => dispatch(getTripJackFlightFareRuleDetails(credential)),
        filterFlightResults: (filters)  => dispatch(filterFlightResults(filters)),
        getFlightPriceDetails: (credential) => dispatch(getFlightPriceDetails(credential)),
        reInitializeFlightPriceDetails: () => dispatch(reInitializeFlightPriceDetails()),
        myAccountAction: ()       => dispatch(myAccountAction()),
        sortFlightResults: (filters)  => dispatch(sortFlightResults(filters)),
        getGoflyFlightSearchResults: (searchQuery)=> dispatch(getGoflyFlightSearchResults(searchQuery)),
        getGoflyFlightFareRuleDetails: (credential) => dispatch(getGoflyFlightFareRuleDetails(credential)),
        geteflyFlightSearchResults: (searchQuery)=> dispatch(geteflyFlightSearchResults(searchQuery)),
        getSatkarFlightSearchResults: (searchQuery)=> dispatch(getSatkarFlightSearchResults(searchQuery)),
        getRiyaFlightSearchResults: (searchQuery)=> dispatch(getRiyaFlightSearchResults(searchQuery)),
        getRiyaFlightFareRuleDetails: (credential) => dispatch(getRiyaFlightFareRuleDetails(credential)),
        geteflyFlightFareRuleDetails: (credential) => dispatch(geteflyFlightFareRuleDetails(credential)),
        getSatkarFlightFareRuleDetails: (credential) => dispatch(getSatkarFlightFareRuleDetails(credential)),
        isFilterChecked: (response) => dispatch(isFilterChecked(response)),
        isFilterCheckedInbound: (response) => dispatch(isFilterCheckedInbound(response)),
        scroll_position: (response) => dispatch(scroll_position(response)),
        kafilaSeatState : (response) => dispatch(kafilaSeatState(response))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FlightSearchDetailsContainer);
