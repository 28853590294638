/* external dependencies */
import React from 'react'
import * as yup from "yup"
import LoginPage from '../../components/shared/login_reset_password';
import { connect } from 'react-redux'
import { loginAction } from '../../actions/shared/sessionAction';
import { verifyEmail, resetPassword, changeComponentClass } from '../../actions/shared/resetPasswordAction';
import { pagesAction, getIp } from '../../actions/shared/sharedAction'

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            email: null,
            loginSuccess : null,
            passwordExpiry : null
        };
    }

    /* create our form validator */
    loginValidator = yup.object()
        .shape({
            username: yup.string()
                .required('User Name is required'),
            password: yup.string()
                .required('Please enter your password')
        });

    handleComponentClass = (values, event) => {
        this.props.changeFlipCardClass(values);
    }

    //Function to redirect after login
    loginRedirection = () => {
        let { agentProductAccess } = this.props;
        let redirectTo;
        this.setState({
            passwordExpiry: null
        }, () => {
           
            // just after successful login, check if user have access to any product, if have than redirect to that product search page otherwise show error message
            if (agentProductAccess.response && agentProductAccess.response.user_access_info.length > 0) {
                if (agentProductAccess.response.user_access_info[0].product_url.indexOf('flight') >= 0) {
                    redirectTo = '/flight-search';
                } else if (agentProductAccess.response.user_access_info[0].product_url.indexOf('hotel') >= 0) {
                    redirectTo = '/hotel-search';
                } else if (agentProductAccess.response.user_access_info[0].product_url.indexOf('train') >= 0) {
                    redirectTo = '/my-account';
                }
                if (redirectTo) {
                    localStorage.setItem('assigned-product', redirectTo);
                    this.setState({ loginSuccess: true });
                    setTimeout(function () {
                        window.location.href = redirectTo;
                    }, 1000);
                }
            } else {
                this.setState({ error: "You don't have any product to access. Please contact your administrator." });
            }
        })
    }

    getAllAccessProducts(allProducts)
    {
        let products = [];
        /* eslint-disable-next-line */
        allProducts.map((item,index) => {
            products.push(item.product_url);
        })
        return products;
    }
    /* define our submit handler */
    handleLoginSubmit = (values, actions) => {
        this.setState({ error: null });

        /* on successful login, forward the user to the Searching container */
        const successCb = (data) => {
            let { agentProductAccess } = this.props;
            let redirectTo;
            if (data.password_expires_in <= 5) {
                this.setState({
                    passwordExpiry: data.password_expires_in
                })
            } else {
                // just after successful login, check if user have access to any product, if have than redirect to that product search page otherwise show error message
                if (agentProductAccess.response && agentProductAccess.response.user_access_info.length > 0) {
                    let products = this.getAllAccessProducts(agentProductAccess.response.user_access_info);
                    if (products.includes('flight-booking')) {
                        redirectTo = '/flight-search';
                    } else if (products.includes('hotel-booking')) {
                        redirectTo = '/hotel-search';
                    } else if (products.includes('train-booking')) {
                        redirectTo = '/my-account';
                    }
                    if (redirectTo) {
                        localStorage.setItem('assigned-product', redirectTo);
                        this.setState({ loginSuccess: true });
                        setTimeout(function () {
                            window.location.href = redirectTo;
                        }, 1000);
                    }
                } else {
                    this.setState({ error: "You don't have any product to access. Please contact your administrator." });
                }
            }
        }

        /* on error, update the error in the state */
        const errorCb = (error) => {
            this.setState({ error: error });
            actions.setSubmitting(false);
        }
        values['from'] = 'front';
        values['ip'] = this.props.getIpResponse.response.ipAddress;
        this.props.loginAction(values, successCb, errorCb);
    };

    handleEmailSubmit = (values, actions, flipCardClass) => {
        this.setState({error: null});
        this.setState({email: values.email}); //stored the email from verifyEmail so we can use it as input in change password 

        /* on successful login, forward the user to the Searching container */
        const successCb = () => {
            actions.setSubmitting(false);
        }

        /* on error, update the error in the state */
        const errorCb = (error) => {
            this.setState({error: error});
            actions.setSubmitting(false);
        }
        this.props.verifyEmailAction(values, flipCardClass, successCb, errorCb);
    };

    handlePasswordSubmit = (values, actions) => {
        this.setState({error: null});

        /* on successful login, forward the user to the Searching container */
        const successCb = () => {
            setTimeout(function(){  window.location.href = '/login'; }, 1000);    
        }

        /* on error, update the error in the state */
        const errorCb = (error) => {
            this.setState({error: error});
            actions.setSubmitting(false);
        }

        values.email = this.state.email; //got the email from state into values
        this.props.resetPasswordAction(values, successCb, errorCb);
    };

    componentDidMount() {
        this.props.pagesAction();
        this.props.getIp();
    }

    render() {
        return (
            <LoginPage
                handleComponentClass={this.handleComponentClass.bind(this)}
                handleLoginSubmit={this.handleLoginSubmit.bind(this)}
                handleEmailSubmit={this.handleEmailSubmit.bind(this)}
                handlePasswordSubmit={this.handlePasswordSubmit.bind(this)}
                errorMsg={this.state.error}
                {...this.props}
                successMsg={this.state.loginSuccess}
                passwordExpiry={this.state.passwordExpiry}
                loginRedirection={this.loginRedirection}
            />
        );
    }

}

const mapStateToProps = (state) => {
    return {
        session : state.session,
        resetPassword: state.resetPassword,
        productResponse: state.shared.productResponse,
        settingResponse: state.shared.settingResponse,
        pagesResponse: state.shared.pagesResponse,
        agentProductAccess: state.shared.agentProductAccess,
        getIpResponse: state.shared.getIpResponse,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loginAction: (credentials, successCb, errorCb) => dispatch(loginAction(credentials, successCb, errorCb)),
        verifyEmailAction: (email, flipCardClass, successCb, errorCb) => dispatch(verifyEmail(email, flipCardClass, successCb, errorCb)),
        resetPasswordAction: (inputs, successCb, errorCb) => dispatch(resetPassword(inputs, successCb, errorCb)),
        pagesAction: (credentials) => dispatch(pagesAction(credentials)),
        changeFlipCardClass: (flipCardClass) => dispatch(changeComponentClass(flipCardClass)),
        getIp: () => dispatch(getIp())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Login)