import React, { Component } from 'react';
import CarouselSlider from '../shared/CarouselComponent';
import ActiveSearchForm from './FlightSearchActiveComponent';
import ActiveMultiCitySearchForm from './FlightMultiCitySearchActiveComponent';
import * as Yup from "yup"
import { Formik } from "formik";
import moment from 'moment';
import { getCurrentDate } from '../../shared/commonHelper';

class FlightSearchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

     //On advance search, on clicking on close set to initial state
     initialState(){
        let first_preferred_departure_time = this.props.searchQuery ? this.props.searchQuery.segments[0].preferred_departure_time:getCurrentDate();
        let first_preferred_arrival_time   = this.props.searchQuery ? this.props.searchQuery.segments[0].preferred_arrival_time:getCurrentDate();
        return {
            activeSarchType: this.props.searchQuery ? this.props.searchQuery.journey_url:'one-way',
            journeyType: this.props.searchQuery ? this.props.searchQuery.journey_type:"1",
            journeyTypeName: this.props.searchQuery ? this.props.searchQuery.journey_type_name:"One Way",
            journeyUrl:this.props.searchQuery ? this.props.searchQuery.journey_url:'one-way',
            origin: this.props.searchQuery ? this.props.searchQuery.segments[0].origin:"JAI",
            destination: this.props.searchQuery ? this.props.searchQuery.segments[0].destination:"",
            flight_cabin_class: this.props.searchQuery ? this.props.searchQuery.segments[0].flight_cabin_class:"1",
            preferred_airlines: this.props.searchQuery ? this.props.searchQuery.preferred_airlines:"",
            adult_count: this.props.searchQuery ? this.props.searchQuery.adult_count:1,
            child_count: this.props.searchQuery ? this.props.searchQuery.child_count:0,
            infant_count: this.props.searchQuery ? this.props.searchQuery.infant_count:0,
            destinationCityName: this.props.searchQuery ? this.props.searchQuery.destinationCityName:"",
            originCityName: this.props.searchQuery ? this.props.searchQuery.originCityName:"Jaipur",
            flightCabinClassName:this.props.searchQuery ? this.props.searchQuery.flightCabinClassName:"Any",
            preferredAirlinesName:this.props.searchQuery &&  this.props.searchQuery.preferredAirlinesName.length !== 0 ? this.props.searchQuery.preferredAirlinesName:"",
            originCityFullName: this.props.searchQuery ? this.props.searchQuery.originCityFullName:"Jaipur(Rajasthan)",
            destinationCityFullName: this.props.searchQuery ? this.props.searchQuery.destinationCityFullName:"",
            first_preferred_departure_time: moment(moment(first_preferred_departure_time)).format('YYYY-MM-DD') + 'T00:00:00',
            first_preferred_arrival_time: moment(moment(first_preferred_arrival_time)).format('YYYY-MM-DD') + 'T00:00:00',
            originCountryName: this.props.searchQuery ? this.props.searchQuery.originCountryName:"India",
            destinationCountryName: this.props.searchQuery ? this.props.searchQuery.destinationCountryName:"",
            pft: this.props.searchQuery ? this.props.searchQuery.pft:"REGULAR",
        }
    }

    handleFlightSearchType(searchType, event) {
        this.setState({ activeSarchType: searchType });
        if (searchType === "one-way")
            this.setState({ journeyType: "1", journeyTypeName: "One Way", journeyUrl: searchType });
        else if (searchType === "round-trip")
            this.setState({ journeyType: "2", journeyTypeName: "Round Trip", journeyUrl: searchType });
        else if (searchType === "multi-city")
            this.setState({ journeyType: "3", journeyTypeName: "Multi City", journeyUrl: searchType });
        else if (searchType === "advance-search")
            this.setState({ journeyType: "4", journeyTypeName: "Advance Search", journeyUrl: searchType });
    }

    handleSubmit(formData, actions) {
        // this.props.scroll_position({childContainer:0,parentContainer:0})
        let preferredAirlinesArray , preferredAirlinesNameArray = [];
        if(Array.isArray(formData.preferred_airlines))
        {
            preferredAirlinesArray = formData.preferred_airlines;
        }else if(!formData.preferred_airlines || formData.preferred_airlines === ''){
            preferredAirlinesArray = null;
        }
        else
        {
            preferredAirlinesArray = [formData.preferred_airlines];
        }

        if(formData.second_preferred_departure_time === 'Invalid dateT00:00:00')
        {
            formData.second_preferred_departure_time = formData.first_preferred_departure_time;
            formData.second_preferred_arrival_time = formData.first_preferred_arrival_time;
        }
        
        preferredAirlinesNameArray = [formData.preferredAirlinesName];
        formData.preferred_airlines = preferredAirlinesArray;
        formData.preferredAirlinesName = preferredAirlinesNameArray;
        formData.journey_type = this.state.journeyType;
        formData.journey_type_name = this.state.journeyTypeName;
        formData.journey_url = this.state.journeyUrl;
        if(true)
        {
            if(parseInt(formData.journey_type) === 1) // One Way
            {
                formData.segments = [];
                formData.segments[0] = {
                    "origin":formData.originAirportCode,
                    "originCityName":formData.originCityName,
                    "originCityFullName":formData.originCityFullName,
                    "destination":formData.destinationAirportCode,
                    "destinationCityName": formData.destinationCityName,
                    "destinationCityFullName": formData.destinationCityFullName,
                    "preferred_departure_time":formData.first_preferred_departure_time,
                    "preferred_arrival_time":formData.first_preferred_arrival_time,
                    "flight_cabin_class":formData.flight_cabin_class,
                    "originAirportCode": formData.originAirportCode,
                    "destinationAirportCode": formData.destinationAirportCode,
                    "originCountryName": formData.originCountryName,
                    "destinationCountryName": formData.destinationCountryName,
                    'flightCabinClassName':formData.flightCabinClassName,
                    "pft": formData.pft,
                }

                formData.multiCityCount = 0;
                if(formData.originCountryName === 'India' && formData.destinationCountryName === 'India')
                {
                    formData.is_domestic = 'true';
                }
                else
                {
                    formData.is_domestic = 'false';
                }
            }
            else if(parseInt(formData.journey_type) === 2 || formData.journey_type === '5') // Round Trip
            {
                formData.segments = [];
                formData.segments[0] = {
                    "origin":formData.originAirportCode,
                    "originCityName":formData.originCityName,
                    "originCityFullName":formData.originCityFullName,
                    "destination":formData.destinationAirportCode,
                    "destinationCityName": formData.destinationCityName,
                    "destinationCityFullName": formData.destinationCityFullName,
                    "preferred_departure_time":formData.first_preferred_departure_time,
                    "preferred_arrival_time":formData.first_preferred_arrival_time,
                    "flight_cabin_class":formData.flight_cabin_class,
                    "originAirportCode": formData.originAirportCode,
                    "destinationAirportCode": formData.destinationAirportCode,
                    "originCountryName": formData.originCountryName,
                    "destinationCountryName": formData.destinationCountryName,
                    'flightCabinClassName':formData.flightCabinClassName,
                    "pft": formData.pft,
                }
                formData.segments[1] = {
                    "origin":formData.destinationAirportCode,
                    "originCityName":formData.destinationCityName,
                    "originCityFullName":formData.destinationCityFullName,
                    "destination":formData.originAirportCode,
                    "destinationCityName": formData.originCityName,
                    "destinationCityFullName": formData.originCityFullName,
                    "preferred_departure_time":formData.second_preferred_departure_time,
                    "preferred_arrival_time":formData.second_preferred_arrival_time,
                    "flight_cabin_class":formData.flight_cabin_class,
                    "originAirportCode": formData.destinationAirportCode,
                    "destinationAirportCode": formData.originAirportCode,
                    "originCountryName": formData.destinationCountryName,
                    "destinationCountryName": formData.originCountryName,
                    'flightCabinClassName':formData.flightCabinClassName,
                    "pft": formData.pft,
                }
                formData.multiCityCount = 2;
                
                if(formData.originCountryName === 'India' && formData.destinationCountryName === 'India')
                {
                    formData.is_domestic = 'true';
                }
                else
                {
                    formData.is_domestic = 'false';
                }
                if(formData.roundTripType === 'LCC')
                {
                    if(!formData.source)
                    {
                        formData.source = ["SG","6E","G8"];
                    }
                    formData.journey_type = "5";
                }
                else if(formData.roundTripType === 'GDS')
                {
                    if(!formData.source)
                    {
                        formData.source = ["GDS"];
                    }
                    formData.journey_type = "5";
                }
                else
                {
                    formData.roundTripType = "normalReturn";
                }
            }
            else if(parseInt(formData.journey_type) === 4)
            {
                if(formData.advanceSearchType === "2")
                {
                    formData.advanceSearchType = "2";
                    formData.segments = [];
                    formData.segments[0] = {
                        "origin":formData.originAirportCode,
                        "originCityName":formData.originCityName,
                        "originCityFullName":formData.originCityFullName,
                        "destination":formData.destinationAirportCode,
                        "destinationCityName": formData.destinationCityName,
                        "destinationCityFullName": formData.destinationCityFullName,
                        "preferred_departure_time":formData.first_preferred_departure_time,
                        "preferred_arrival_time":formData.first_preferred_arrival_time,
                        "flight_cabin_class":formData.flight_cabin_class,
                        "originAirportCode": formData.originAirportCode,
                        "destinationAirportCode": formData.destinationAirportCode,
                        "originCountryName": formData.originCountryName,
                        "destinationCountryName": formData.destinationCountryName,
                        'flightCabinClassName':formData.flightCabinClassName,
                        "pft": formData.pft,
                    }
                    formData.segments[1] = {
                        "origin":formData.destinationAirportCode,
                        "originCityName":formData.destinationCityName,
                        "originCityFullName":formData.destinationCityFullName,
                        "destination":formData.originAirportCode,
                        "destinationCityName": formData.originCityName,
                        "destinationCityFullName": formData.originCityFullName,
                        "preferred_departure_time":formData.second_preferred_departure_time,
                        "preferred_arrival_time":formData.second_preferred_arrival_time,
                        "flight_cabin_class":formData.flight_cabin_class,
                        "originAirportCode": formData.destinationAirportCode,
                        "destinationAirportCode": formData.originAirportCode,
                        "originCountryName": formData.destinationCountryName,
                        "destinationCountryName": formData.originCountryName,
                        'flightCabinClassName':formData.flightCabinClassName,
                        "pft": formData.pft,
                    }
    
                    if(formData.originCountryName === 'India' && formData.destinationCountryName === 'India')
                    {
                        formData.is_domestic = 'true';
                    }
                    else
                    {
                        formData.is_domestic = 'false';
                    }
                }
                else
                {
                    formData.advanceSearchType = "1";
                    formData.segments = [];
                    formData.segments[0] = {
                        "origin":formData.originAirportCode,
                        "originCityName":formData.originCityName,
                        "originCityFullName":formData.originCityFullName,
                        "destination":formData.destinationAirportCode,
                        "destinationCityName": formData.destinationCityName,
                        "destinationCityFullName": formData.destinationCityFullName,
                        "preferred_departure_time":formData.first_preferred_departure_time,
                        "preferred_arrival_time":formData.first_preferred_arrival_time,
                        "flight_cabin_class":formData.flight_cabin_class,
                        "originAirportCode": formData.originAirportCode,
                        "destinationAirportCode": formData.destinationAirportCode,
                        "originCountryName": formData.originCountryName,
                        "destinationCountryName": formData.destinationCountryName,
                        'flightCabinClassName':formData.flightCabinClassName,
                        "pft": formData.pft,
                    }

                    if(formData.originCountryName === 'India' && formData.destinationCountryName === 'India')
                    {
                        formData.is_domestic = 'true';
                    }
                    else
                    {
                        formData.is_domestic = 'false';
                    }
                }
                delete formData.destinationCountryName; 
                delete formData.originCountryName; 
                delete formData.flightCabinClassName;
                delete formData.multiCityCount;
                if(formData.preferred_airlines && formData.preferred_airlines[0] === '')
                {
                    formData.preferred_airlines = "";
                    formData.preferredAirlinesName = "";
                }

            }else if(parseInt(formData.journey_type) === 3){
                let isDomestic = 'true';
                formData.originAirportCode = formData.segments[0].origin
                formData.destinationAirportCode = formData.segments[0].destination
                formData.segments.map((item,index) => {
                    if(item.destinationCountryName !== item.originCountryName){
                        isDomestic = 'false';
                    }
                    return true;
                });
                formData.is_domestic = isDomestic;
            }

            if(this.props.searchQuery && this.props.searchQuery.source && !formData.source)
            {
                formData.source = this.props.searchQuery.source
            }
            
            if(this.props.searchQuery && this.props.searchQuery.source && this.props.searchQuery.source.length === 0)
            {
                delete this.props.searchQuery.source
            }
            else if(formData.source && formData.source.length === 0)
            {
                delete formData.source;
            }else{
                if(formData.source && formData.source.includes("GDS") >= 0)
                {
                    let preferred_airline = [];
                    /* eslint-disable-next-line */
                    this.props.allAirlinesData && this.props.allAirlinesData.response && this.props.allAirlinesData.response.map((item,index) => {
                        if(item.airline_name !== '')
                        {
                            preferred_airline.push(item.airline_code);
                        }
                    });
                    formData.preferred_airlines = preferred_airline;
                }
            }
            
            delete formData.origin; 
            delete formData.originCityName; 
            //delete formData.originCityFullName; 
            delete formData.destination;
            delete formData.destinationCityName;
            //delete formData.destinationCityFullName; 
            delete formData.first_preferred_departure_time; 
            delete formData.first_preferred_arrival_time; 
            delete formData.second_preferred_departure_time; 
            delete formData.second_preferred_arrival_time; 
            delete formData.flight_cabin_class;
            //delete formData.originAirportCode;
            //delete formData.destinationAirportCode; 
        }
        this.props.flightSearchSaveQuery(formData);
        if(this.props.modifySearch && this.props.history.location.pathname === '/flight-search-details')
        {
            const is_kafila_user = localStorage.getItem('is_kafila_user');
            const { searchQuery } = this.props;
           // const successCb = () => {
            //    this.props.getKafilaFlightSearchResults(searchQuery);
           // }
            setTimeout(() => {
                if(is_kafila_user === 'Y' && ['1','2','5'].includes(searchQuery.journey_type) && (searchQuery.pft === 'REGULAR' || searchQuery.pft === 'STUDENT' || searchQuery.pft === 'SENIOR_CITIZEN'))
                {
                    if (searchQuery.is_domestic === 'false' &&  (searchQuery.journey_url === "round-trip" || searchQuery.journey_url === "round-trip-complete" || searchQuery.journey_url === "special-return" ||  searchQuery.journey_url === 'lcc-special-return')) {
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                    this.props.getKafilaFlightSearchResults(searchQuery);
                        }
                    }
                }
            }, 1000);
            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
            if(searchQuery.pft === 'REGULAR')
            {
                setTimeout(() => {
                    if(['1', '2'].includes(searchQuery.journey_type))
                        {
                                this.props.getTripJackFlightSearchResults(searchQuery);
                        }
                    }, 2000);    
            }
            else if(['1', '2'].includes(searchQuery.journey_type))
            {
                this.props.getTripJackFlightSearchResults(searchQuery);
            }
        }
            setTimeout(() => {
                if (['1', '2','5'].includes(searchQuery.journey_type) && searchQuery.pft === 'REGULAR') {
                    if (searchQuery.flightCabinClassName === 'Business' || searchQuery.flightCabinClassName === "First" || searchQuery.flightCabinClassName === 'Premium Economy') {
                    } else {
                        // if (searchQuery.is_domestic === 'false' &&  (searchQuery.journey_url === "round-trip" || searchQuery.journey_url === "round-trip-complete" || searchQuery.journey_url === "special-return" ||  searchQuery.journey_url === 'lcc-special-return') && (searchQuery.roundTripType !== "LCC" && searchQuery.roundTripType !== "GDS")) {
                        //     console.log(366,searchQuery.roundTripType === "LCC")
                        //     this.props.getGoflyFlightSearchResults(searchQuery);
                        // }
                        // else{
                        // this.props.getGoflyFlightSearchResults(searchQuery);
                        // }
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                        if(this.props.searchQuery.is_domestic === 'false'){
                            if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                                if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                    this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                                }
                            }else{
                                this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                        }
                    }
                    }
                }
            }, 3000);

            setTimeout(() => {
                if (['1', '2','5'].includes(searchQuery.journey_type) && searchQuery.pft === 'REGULAR') {
                    if (searchQuery.flightCabinClassName === 'Business' || searchQuery.flightCabinClassName === "First" || searchQuery.flightCabinClassName === 'Premium Economy') {
                    } else {
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                        if(this.props.searchQuery.is_domestic === 'false'){
                            if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                                if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                    this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                                }
                            }else{
                                this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                        }
                    }
                    }
                }
            }, 3000);


                 
            setTimeout(() => {
             
                if (['1', '2','5'].includes(searchQuery.journey_type) && 
                (searchQuery.pft === 'REGULAR' || searchQuery.pft === 'STUDENT' || searchQuery.pft === 'SENIOR_CITIZEN') &&
                 (searchQuery.journey_url !== "multi-city" && searchQuery.journey_url !== 'advance-search')) {
                    
                        this.props.getRiyaFlightSearchResults(this.props.searchQuery);
                    
                }
            }, 3000);

            setTimeout(() => {
                if (searchQuery.pft === 'REGULAR' && searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search') {
                    if (searchQuery.flightCabinClassName === 'Business' || searchQuery.flightCabinClassName === "First" || searchQuery.flightCabinClassName === 'Premium Economy') {
                    } else {
                        if (searchQuery.is_domestic === 'false' &&  (searchQuery.journey_url === "round-trip" || searchQuery.journey_url === "round-trip-complete" || searchQuery.journey_url === "special-return" ||  searchQuery.journey_url === 'lcc-special-return')) {
                        }else{
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                        this.props.getAirIQFlightSearchResults(searchQuery);
                            }
                        }
                    }
                }
            }, 3000);
            
            setTimeout(() => {
                if(searchQuery.pft === 'REGULAR' && searchQuery.journey_url !== "multi-city" && searchQuery.journey_url !== 'advance-search')
                    {
                        if(searchQuery.flightCabinClassName==='Business'||searchQuery.flightCabinClassName==="First"||searchQuery.flightCabinClassName==='Premium Economy'){
                           
                        }else {
                            if (searchQuery.is_domestic === 'false' &&  (searchQuery.journey_url === "round-trip" || searchQuery.journey_url === "round-trip-complete" || searchQuery.journey_url === "special-return" ||  searchQuery.journey_url === 'lcc-special-return')) {
                            }else{
                                if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                            this.props.geteflyFlightSearchResults(searchQuery);
                                }
                            }
                        }
                            
                        
                    }
            }, 3000);
            
            if(searchQuery.pft === 'REGULAR' || searchQuery.pft === 'STUDENT'  || searchQuery.pft === 'SENIOR_CITIZEN')
            {
                this.props.getFlightSearchResults(searchQuery);
                setTimeout(() => {
                    if(['3'].includes(searchQuery.journey_type)&& searchQuery.journey_url === 'multi-city' && searchQuery.is_domestic === 'false')
                        {
                                this.props.getTripJackFlightSearchResults(searchQuery);
                        }
                    }, 2000); 
            }
            
            this.props.toggleModifySearch();
        }
        else
        {
            this.props.history.push({
                pathname:"/flight-search-details",
                state:{
                    searchAPINo : false
                }
            });
        }
    };

    resetandtoogle= () => {
        this.setState(this.initialState());
        this.props.toggleModifySearch()
    }

    //Get dynamic component name
    components = {
        'one-way': ActiveSearchForm,
        'round-trip': ActiveSearchForm,
        'multi-city': ActiveMultiCitySearchForm,
        'advance-search': ActiveSearchForm
    };

    render() {
        const { bannerResponse, noticeResponse } = this.props;
       
        let validationSchemaBasicDetails = '';
        if(this.state.activeSarchType === 'multi-city'){
             validationSchemaBasicDetails = Yup.object().shape({
                // origin:
                //     Yup.string()
                //         .notOneOf([Yup.ref('destination'), null], 'Departure city and Destination city must be different.')
                //         .required('Departure city is required.'),
                // destination:
                //     Yup.string()
                //         .notOneOf([Yup.ref('origin'), null], 'Departure city and Destination city must be different.')
                //         .required('Destination city is required.'),
                adult_count: 
                    Yup.number()
                    .test('adult_count', 'Please enter atleast one child or one adult',
                        function(value) {
                            let adultCount = this.resolve(Yup.ref("adult_count"));
                            let childCount = this.resolve(Yup.ref("child_count"));
                            if(adultCount === 0 && childCount === 0)
                            {
                                return false;
                            }
                            else if(adultCount !== 0 || childCount !== 0)
                            {
                                return true;
                            }
                        })
                        .test('infant_count', 'Infant count can not be greater than adult count',
                            function(value) {
                                let adultCount = this.resolve(Yup.ref("adult_count"));
                                let infantCount = this.resolve(Yup.ref("infant_count"));
                                
                                if(infantCount > adultCount)
                                {
                                    return false;
                                }
                                else
                                {
                                    return true;
                                }
                            }),
                multiCityCount:
                    Yup.number()
                        .test('multiCityCount', 'Add any Source and Destination city or airport codes into itinerary.',
                            function (value) {
                                let multiCityCount = this.resolve(Yup.ref("multiCityCount"));
                                if (multiCityCount === 0) {
                                    return false;
                                }else   
                                    return true;
                            })
                        .test('multiCityCount', 'Add more itinerary.',
                            function (value) {
                                let multiCityCount = this.resolve(Yup.ref("multiCityCount"));
                                if (multiCityCount === 1) {
                                    return false;
                                }else   
                                    return true;
                            })
            });
        }else{
            validationSchemaBasicDetails = Yup.object().shape({
                origin:
                    Yup.string()
                        .required('Departure city is required.')
                        .notOneOf([Yup.ref('destination'), null], 'Departure city and Destination city must be different.'),
                destination:
                    Yup.string()
                        .required('Destination city is required.')
                        .notOneOf([Yup.ref('origin'), null], 'Departure city and Destination city must be different.'),
                adult_count: 
                    Yup.number()
                    .test('adult_count', 'Please enter atleast one child or one adult',
                        function(value) {
                            let adultCount = this.resolve(Yup.ref("adult_count"));
                            let childCount = this.resolve(Yup.ref("child_count"));
                            if(adultCount === 0 && childCount === 0)
                            {
                                return false;
                            }
                            else if(adultCount !== 0 || childCount !== 0)
                            {
                                return true;
                            }
                        })
                        .test('infant_count', 'Infant count can not be greater than child count',
                            function(value) {
                                let adultCount = this.resolve(Yup.ref("adult_count"));
                                let infantCount = this.resolve(Yup.ref("infant_count"));
                                
                                if(infantCount > adultCount)
                                {
                                    return false;
                                }
                                else
                                {
                                    return true;
                                }
                            })
                        .test('adult_count', 'Child and Adult can not be greater than 9',
                            function(value) {
                                let adultCount = this.resolve(Yup.ref("adult_count"));
                                let childCount = this.resolve(Yup.ref("child_count"));
                                if((adultCount+childCount) > 9)
                                {
                                    return false;
                                }
                                else
                                {
                                    return true;
                                }
                            }),
                
            });
        }
       
        let first_preferred_departure_time = this.props.searchQuery ? this.props.searchQuery.journeyUrl === 'multi-city' ? this.props.searchQuery.segments && this.props.searchQuery.segments.length && this.props.searchQuery.segments[this.props.searchQuery.segments.length-1].preferred_departure_time : this.props.searchQuery.segments[0].preferred_departure_time : getCurrentDate();
        let first_preferred_arrival_time   = this.props.searchQuery ? this.props.searchQuery.journeyUrl === 'multi-city'? this.props.searchQuery.segments && this.props.searchQuery.segments.length && this.props.searchQuery.segments[this.props.searchQuery.segments.length-1].preferred_arrival_time : this.props.searchQuery.segments[0].preferred_arrival_time :getCurrentDate();
        let second_preferred_departure_time = this.props.searchQuery && (this.props.searchQuery.journey_type === '2' || ( this.props.searchQuery.journey_type === '4' && this.props.searchQuery.advanceSearchType === '2')) ? this.props.searchQuery.segments[1].preferred_departure_time:this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[0] && this.props.searchQuery.segments[0].preferred_departure_time;
        let second_preferred_arrival_time   = this.props.searchQuery && (this.props.searchQuery.journey_type === '2' || ( this.props.searchQuery.journey_type === '4' && this.props.searchQuery.advanceSearchType === '2')) ? this.props.searchQuery.segments[1].preferred_arrival_time:this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[0] && this.props.searchQuery.segments[0].preferred_arrival_time;
        let multiCityPreferedDepartureTime= this.props.searchQuery ? this.props.searchQuery.segments && this.props.searchQuery.segments.length && this.props.searchQuery.segments[this.props.searchQuery.segments.length-1].preferred_departure_time  : getCurrentDate();
        //Dynamically render component
        const ComponentName = this.components[this.state.activeSarchType || 'oneWay'];
        let classDynamic = '';
        if(this.state.activeSarchType === "advance-search")
            classDynamic = 'search-mid advance-search-result';
        else if(this.state.activeSarchType === "multi-city")
            classDynamic = 'search-mid multi-search-result';
        else if(this.state.activeSarchType === "round-trip")
            classDynamic = 'search-mid round-trip-result';
        else 
            classDynamic = 'search-mid ';

        let importantMessage = '';
        let count = 1;
        if(noticeResponse.response){
            for(let i = 0;i< noticeResponse.response.length;i++){
                if(noticeResponse.response[i].is_important === 1)
                {
                    importantMessage += '('+count+') '+noticeResponse.response[i].notice;
                    count = count + 1;
                }
            }
        }
        importantMessage = importantMessage.replace(/<p>/g,'');
        importantMessage = importantMessage.replace(/<\/\p>/g,'');
        return (
            <React.Fragment>
                {/*Search Component Start*/}
                {
                    !this.props.modifySearch
                        ?
                        <section className="mid-wrapper">
                            <div className="container">
                                <div className= {classDynamic}>
                                    {
                                        importantMessage !== '' &&    
                                        <div className="important-text-search block">
                                            <div className="important-text-search-inner">
                                                <div className="imp-block">
                                                    <strong className="imp-heading">Important Message</strong>
                                                    {/* <Maquree text={importantMessage}/>*/}
                                                    {/* eslint-disable-next-line */}
                                                    <marquee scrollamount="3">
                                                        <p dangerouslySetInnerHTML={{ __html: importantMessage }} /> 
                                                    </marquee>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="search-inner-block">
                                        <div className="col-left">
                                            <div className="search-inner-block">
                                                <div className="tab-container">
                                                    <div className="tab-nav">
                                                        <ul className="tab-list">
                                                            <li className={(this.state.activeSarchType === "one-way") ? "active" : ""}><span className="anchor" onClick={this.handleFlightSearchType.bind(this, 'one-way')} >One Way</span></li>
                                                            <li className={(this.state.activeSarchType === "round-trip") ? "active" : ""}><span className="anchor" onClick={this.handleFlightSearchType.bind(this, 'round-trip')}>Round Trip</span></li>
                                                            <li className={(this.state.activeSarchType === "multi-city") ? "active" : ""}><span className="anchor" onClick={this.handleFlightSearchType.bind(this, 'multi-city')}>Multi-City</span></li>
                                                            <li className={(this.state.activeSarchType === "advance-search") ? "active" : ""}><span className="anchor" onClick={this.handleFlightSearchType.bind(this, 'advance-search')}>Advance search</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className="tab-content">
                                                        <Formik
                                                            initialValues={{
                                                                direct_flight: "false",
                                                                one_stop_flight: "false",
                                                                journey_type: "1",
                                                                origin: "JAI",
                                                                destination: "",
                                                                first_preferred_departure_time: moment(moment(getCurrentDate())).format('YYYY-MM-DD') + 'T00:00:00',
                                                                first_preferred_arrival_time: moment(moment(getCurrentDate())).format('YYYY-MM-DD') + 'T00:00:00',
                                                                second_preferred_departure_time: moment(moment(getCurrentDate())).format('YYYY-MM-DD') + 'T00:00:00',
                                                                second_preferred_arrival_time: moment(moment(getCurrentDate())).format('YYYY-MM-DD') + 'T00:00:00',
                                                                flight_cabin_class: "1",
                                                                preferred_airlines: "",
                                                                adult_count: 1,
                                                                child_count: 0,
                                                                infant_count: 0,
                                                                destinationCityName: "",
                                                                destinationCityFullName:"",
                                                                originCityName: "Jaipur",
                                                                originCityFullName:"Jaipur, India",
                                                                flightCabinClassName:"Any",
                                                                preferredAirlinesName:"",
                                                                multiCityCount: 0,
                                                                originAirportCode: "JAI",
                                                                destinationAirportCode: "",
                                                                originCountryName: "India",
                                                                destinationCountryName: "",
                                                                roundTripType  : 'normalReturn',
                                                                pft  : 'REGULAR'
                                                            }}
                                                            validationSchema={validationSchemaBasicDetails}
                                                            onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                                                            render={(formikProps) =>
                                                                <ComponentName
                                                                    {...formikProps}
                                                                    {...this.props}
                                                                    {...this.state}
                                                                    handleFlightSearchType={this.handleFlightSearchType.bind(this)}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-right">
                                            {bannerResponse.response &&
                                                <CarouselSlider carousalClass="banner-carosuel" dataSlider={bannerResponse.response} />
                                            }
                                        </div>
                                    </div>
                                    {/*news-section Start*/}
                                    {/* <div className="latest-news-block">
                                        <fieldset className="news-inner-info">
                                            <legend>Latest News</legend>
                                            {noticeResponse.response &&
                                                <CarouselSlider carousalClass="owl-carousel-news" dataSlider={noticeResponse.response} />
                                            }
                                        </fieldset>
                                    </div> */}
                                    {/*news-section end*/}
                                </div>
                            </div>
                        </section>
                        :
                        <div className="search-inner-block">
                            <div className="col-left">
                                <div className="popup-head">
                                    <h2>Modify Search</h2>
                                    <div className="popup-close"><span className="icon icon-cross" onClick={() => this.resetandtoogle()}></span></div>
                                </div>
                                <div className="search-inner-block">
                                    <div className="tab-container">
                                        <div className="tab-nav">
                                            <ul className="tab-list">
                                                <li className={(this.state.activeSarchType === "one-way" ) ? "active" : ""}><span className="anchor" onClick={this.handleFlightSearchType.bind(this, 'one-way')} >One Way</span></li>
                                                <li className={(this.state.activeSarchType === "round-trip") ? "active" : ""}><span className="anchor" onClick={this.handleFlightSearchType.bind(this, 'round-trip')}>Round Trip</span></li>
                                                <li className={(this.state.activeSarchType === "multi-city") ? "active" : ""}><span className="anchor" onClick={this.handleFlightSearchType.bind(this, 'multi-city')}>Multi-City</span></li>
                                                <li className={(this.state.activeSarchType === "advance-search") ? "active" : ""}><span className="anchor" onClick={this.handleFlightSearchType.bind(this, 'advance-search')}>Advance search</span></li>
                                            </ul>
                                        </div>
                                        <div className="tab-content">
                                            <Formik
                                                initialValues={{
                                                    direct_flight: this.props.searchQuery ? this.props.searchQuery.direct_flight : "false",
                                                    one_stop_flight: "false",
                                                    journey_type: this.props.searchQuery ? this.props.searchQuery.journey_type:"1",
                                                    roundTripType  : this.props.searchQuery ? this.props.searchQuery.roundTripType:'normalReturn',
                                                    origin: this.props.searchQuery ? this.props.searchQuery.segments[0].origin:"JAI",
                                                    destination: this.props.searchQuery ? this.props.searchQuery.segments[0].destination:"",
                                                    first_preferred_departure_time: this.props.searchQuery.journey_url === 'multi-city' ? moment(moment(multiCityPreferedDepartureTime)).format('YYYY-MM-DD') + 'T00:00:00' : moment(moment(first_preferred_departure_time)).format('YYYY-MM-DD') + 'T00:00:00',
                                                    first_preferred_arrival_time: moment(moment(first_preferred_arrival_time)).format('YYYY-MM-DD') + 'T00:00:00',
                                                    second_preferred_departure_time: moment(moment(second_preferred_departure_time)).format('YYYY-MM-DD') + 'T00:00:00',
                                                    second_preferred_arrival_time: moment(moment(second_preferred_arrival_time)).format('YYYY-MM-DD') + 'T00:00:00',
                                                    flight_cabin_class: this.props.searchQuery ? this.props.searchQuery.segments[0].flight_cabin_class:"1",
                                                    preferred_airlines: this.props.searchQuery ? this.props.searchQuery.preferred_airlines:"",
                                                    adult_count: this.props.searchQuery ? this.props.searchQuery.adult_count:1,
                                                    child_count: this.props.searchQuery ? this.props.searchQuery.child_count:0,
                                                    infant_count: this.props.searchQuery ? this.props.searchQuery.infant_count:0,
                                                    destinationCityName: this.props.searchQuery ? this.props.searchQuery.segments[0].destinationCityName:"",
                                                    originCityName: this.props.searchQuery ? this.props.searchQuery.segments[0].originCityName:"Jaipur",
                                                    flightCabinClassName:this.props.searchQuery ? this.props.searchQuery.flightCabinClassName:"Any",
                                                    preferredAirlinesName: this.props.searchQuery &&  this.props.searchQuery.preferredAirlinesName.length === 0 ? this.props.searchQuery.preferredAirlinesName:"",
                                                    originCityFullName: this.props.searchQuery ? this.props.searchQuery.segments[0].originCityFullName:"Jaipur, India",
                                                    destinationCityFullName: this.props.searchQuery ? this.props.searchQuery.segments[0].destinationCityFullName:"",
                                                    originAirportCode: this.props.searchQuery ? this.props.searchQuery.segments[0].originAirportCode:"JAI",
                                                    destinationAirportCode: this.props.searchQuery ? this.props.searchQuery.segments[0].destinationAirportCode:"",
                                                    originCountryName: this.props.searchQuery ? this.props.searchQuery.segments[0].originCountryName:"India",
                                                    destinationCountryName: this.props.searchQuery ? this.props.searchQuery.segments[0].destinationCountryName:"",
                                                    multiCityCount        : this.props.searchQuery ? this.props.searchQuery.segments.length : 0,
                                                    pft: this.props.searchQuery ? this.props.searchQuery.pft:"REGULAR",

                                                }}
                                                validationSchema={validationSchemaBasicDetails}
                                                onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                                                render={(formikProps) =>
                                                    <ComponentName
                                                        {...formikProps}
                                                        {...this.props}
                                                        {...this.state}
                                                        handleFlightSearchType={this.handleFlightSearchType.bind(this)}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>         
                }
                {/*Search Component End*/}
            </React.Fragment>
        )
    }
}
export default FlightSearchComponent
