import React from 'react';
import AgentHeaderNavigation from '../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../agent/AgentCommonHeaderComponent';
import { dateFormat,currencyFormat ,jsUcfirst, getCurrentDate} from '../../shared/commonHelper';
import AgentLeftBar from '../agent/AgentLeftBarComponent';
import history from "../../shared/history";
import PerfectScrollbar from 'react-perfect-scrollbar'
import * as flightHelper from '../../shared/flightHelper';

// import FlightFareSummaryComponent from '../flights/booking/FlightFareSummaryComponent';

class AgentBookingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideMsg: false,
            leftNavActive: '/booking-calendar',
            url: history.location.pathname,
            currentDate: getCurrentDate(),
            fareSummaryPopup: false ,
            showOfferedPrice : null,	
            transactionNo : null,
            pnr : null,
            booking_status : null,
            showConfirmationPopup : false,
            showCancelPopup: false,
            releasePnrStatus : null,
            confimationAction : null,
            loading:false,
            BookingDatas:""
           }
        }
     
    handleConfirmationPopup = (event,action)=>{
        this.setState({
            showConfirmationPopup : event,
            confimationAction : action
        })		
    }    
    
    handleConfimationAction = (data, successCb, errorCb,successCb1) => {
        if (data && data.details.booking_from === 'tripjack') {
            if (this.state.confimationAction === 'confirm') {
                const bookDetails = (response) => {
                    let bookingData = '';
                    bookingData = {
                        "pnr": data.details && data.details.pnr,
                        "booking_id": parseInt(data.booking_id),
                        "offered_fare": Number(data && data.details && data.details.fare.offered_fare),
                        "agent_discount": Number(data && data.details && data.details.fare.agent_discount),
                        "service_charge": Number(data && data.details && data.details.fare.service_charge),
                        "cgst": Number(data && data.details && data.details.fare.cgst),
                        "igst": Number(data && data.details && data.details.fare.igst),
                        "sgst": Number(data && data.details && data.details.fare.sgst),
						"category_name" : data.details && data.details.category_name,
                        "passengers": [
                            {
                                "pax_id": data && data.details && data.details.passengers && data.details.passengers[0].pax_id,
                                "pax_type": parseInt(data && data.details && data.details.passengers && data.details.passengers[0].pax_type),
                                "pax_title": data && data.details && data.details.passengers && data.details.passengers[0].pax_title,
                                "ticket_id": data && data.details && data.details.passengers && data.details.passengers[0].ticket_id,
                                "ticket_no": data && data.details && data.details.passengers && data.details.passengers[0].ticket_no,
                                "ticket_status": data && data.details && data.details.passengers && data.details.passengers[0].ticket_status,
                                "validating_airline": data && data.details && data.details.passengers && data.details.passengers[0].validating_airline,
                                "first_name": data && data.details && data.details.passengers && data.details.passengers[0].first_name,
                                "last_name": data && data.details && data.details.passengers && data.details.passengers[0].last_name,
                                "dateofbirth": data && data.details && data.details.passengers && data.details.passengers[0].dateofbirth,
                                "passport_no": data && data.details && data.details.passengers && data.details.passengers[0].passport_no,
                                "passport_expire_date": data && data.details && data.details.passengers && data.details.passengers[0].passport_expire_date,
                                "passport_issue_place": data && data.details && data.details.passengers && data.details.passengers[0].passport_issue_place,
                                "passport_issue_date": data && data.details && data.details.passengers && data.details.passengers[0].passport_issue_date,
                                "ff_no": data && data.details && data.details.passengers && data.details.passengers[0].ff_no,
                                "visa_type": data && data.details && data.details.passengers && data.details.passengers[0].visa_type,
                                "city": data && data.details && data.details.passengers && data.details.passengers[0].city,
                                "country_code": data && data.details && data.details.passengers && data.details.passengers[0].country_code,
                                "country_name": data && data.details && data.details.passengers && data.details.passengers[0].country_name,
                                "nationality": data && data.details && data.details.passengers && data.details.passengers[0].nationality,
                                "contact_no": data && data.details && data.details.passengers && data.details.passengers[0].pax_title,
                                "email": data && data.details && data.details.passengers && data.details.passengers[0].email,
                                "address_line1": data && data.details && data.details.passengers && data.details.passengers[0].address_line1,
                                "address_line2": data && data.details && data.details.passengers && data.details.passengers[0].address_line2,
                                "is_lead_pax": data && data.details && data.details.passengers && data.details.passengers[0].is_lead_pax,
                                "gst_company_address": data && data.details && data.details.passengers && data.details.passengers[0].gst_company_address,
                                "gst_company_contact_number": data && data.details && data.details.passengers && data.details.passengers[0].gst_company_contact_number,
                                "gst_company_email": data && data.details && data.details.passengers && data.details.passengers[0].gst_company_email,
                                "gst_company_name": data && data.details && data.details.passengers && data.details.passengers[0].gst_company_name,
                                "gst_number": data && data.details && data.details.passengers && data.details.passengers[0].gst_number,
                                "fare": {
                                    "base_fare": data && data.details && data.details.fare.pax_title
                                },
                            }
                        ],
                        "segments": [
                            {
                                "origin": data && data.details && data.details.segments && data.details.segments[0][0].origin,
                                "destination": data && data.details && data.details.segments && data.details.segments[0][0].destination,
                                "airline_code": data && data.details && data.details.segments && data.details.segments[0][0].airline_code,
                                "flight_number": data && data.details && data.details.segments && data.details.segments[0][0].flight_no,
                                "origin_airport_name": data && data.details && data.details.segments && data.details.segments[0][0].origin_airport_name,
                                "origin_airport_terminal": data && data.details && data.details.segments && data.details.segments[0][0].origin_airport_terminal,
                                "destination_airport_name": data && data.details && data.details.segments && data.details.segments[0][0].destination_airport_name,
                                "destination_airport_terminal": data && data.details && data.details.segments && data.details.segments[0][0].destination_airport_terminal,
                                "airline_pnr": data && data.details && data.details.segments && data.details.segments[0][0].airline_pnr ? data && data.details && data.details.segments && data.details.segments[0][0].airline_pnr : null,
                                "fare_class": data && data.details && data.details.segments && data.details.segments[0][0].fare_class,
                                "stop_point": data && data.details && data.details.segments && data.details.segments[0][0].stop_point,
                                "stop_point_arrival_time": data && data.details && data.details.segments && data.details.segments[0][0].stop_point_arrival_time,
                                "stop_point_departure_time": data && data.details && data.details.segments && data.details.segments[0][0].stop_point_departure_time,
								//"baggage": data.details && data.details.default_baggage
                            },
                        ],
                        "fare": {
                            "published_fare": data && data.details && data.details.fare.published_fare,
                            "offered_fare": data && data.details && data.details.fare.offered_fare,
                            "other_charges": data && data.details && data.details.fare.other_charges,
                            "tax": data && data.details && data.details.fare.tax,
                            "total_baggage_charges": data && data.details && data.details.fare.total_baggage_charges,
                            "total_meal_charges": data && data.details && data.details.fare.total_meal_charges,
                            "total_seat_charges": data && data.details && data.details.fare.total_seat_charges,
                        },
                        "tds": data && data.details && data.details.fare.tds,
                        "source": data && data.details.source,
                        "drop_booking": false,
                        "price_modified": {
                            "yq_tax": 0,
                            "tbo_tds": 0,
                            "admin_unique_id": 0,
                            "agent_unique_id": 0,
                            "tds": 0,
                            "agent_discount": 0,
                            "tbo_earned": 0,
                            "other_charges": 0,
                            "base_fare": 0,
                            "service_charge_amount": 0,
                            "sgst": 0,
                            "cgst": 0,
                            "igst": 0,
                            "offered_fare": 0,
                            "published_fare": 0,
                            "tax": 0
                        },
                        "origin": data && data.details.origin,
                        "destination": data && data.details.destination,
                        "booking_type": data && data.details && data.details.booking_type,
                        "tripjack_booking_id": data && data.details && data.details.transaction_no,
                        "tripjack_bookingId": data && data.details && data.details.transaction_no,
                    }
                    this.setState({BookingDatas:bookingData})
                    this.props.tripJackBookDetails(bookingData, successCb1, errorCb)
                }

                const bookingConfirm = () => {
                    let bookConfirmRequest = {
                        "tripjack_booking_id": data && data.details && data.details.transaction_no,
                        "booking_id": data.booking_id,
                        "offered_fare": data.details.fare.offered_fare_old,
                    }
                    this.props.tripJackBookConfirm(bookConfirmRequest, bookDetails, errorCb)
                }

                let validateData = {
                    "booking_id": data.booking_id,
                    "tripjack_booking_id": data && data.details && data.details.transaction_no
                }
                this.props.validateTripJackTicket(validateData, bookingConfirm, errorCb)
            } else if (this.state.confimationAction === 'release') {
                this.releaseHoldTripJack(data);
            }
            this.setState({
                showConfirmationPopup : false,
                loading:true
            });
        }  else if (data && data.details.booking_from === "riya") {
            if (this.state.confimationAction === 'confirm') {
               
                const bookDetails = (response) => {
                    let bookingData = '';
                    bookingData = {
                        "trace_id": data.details.trace_id,
                        "pnr": data.details && data.details.pnr,
                        "booking_id": parseInt(data.booking_id),
                        "offered_fare": Number(data && data.details && data.details.fare.offered_fare),
                        "agent_discount": Number(data && data.details && data.details.fare.agent_discount),
                        "agent_discount_type": "",
                        "service_charge": Number(data && data.details && data.details.fare.service_charge),
                        "cgst": Number(data && data.details && data.details.fare.cgst),
                        "igst": Number(data && data.details && data.details.fare.igst),
                        "sgst": Number(data && data.details && data.details.fare.sgst),
                        "category_name": data.details && data.details.category_name,
                        "passengers": [
                            {
                                "pax_id": data && data.details && data.details.passengers && data.details.passengers[0].pax_id,
                                "pax_type": parseInt(data && data.details && data.details.passengers && data.details.passengers[0].pax_type),
                                "pax_title": data && data.details && data.details.passengers && data.details.passengers[0].pax_title,
                                "ticket_id": data && data.details && data.details.passengers && data.details.passengers[0].ticket_id,
                                "ticket_no": data && data.details && data.details.passengers && data.details.passengers[0].ticket_no,
                                "ticket_status": data && data.details && data.details.passengers && data.details.passengers[0].ticket_status,
                                "validating_airline": data && data.details && data.details.passengers && data.details.passengers[0].validating_airline,
                                "first_name": data && data.details && data.details.passengers && data.details.passengers[0].first_name,
                                "last_name": data && data.details && data.details.passengers && data.details.passengers[0].last_name,
                                "dateofbirth": data && data.details && data.details.passengers && data.details.passengers[0].dateofbirth,
                                "passport_no": data && data.details && data.details.passengers && data.details.passengers[0].passport_no,
                                "passport_expire_date": data && data.details && data.details.passengers && data.details.passengers[0].passport_expire_date,
                                "passport_issue_place": data && data.details && data.details.passengers && data.details.passengers[0].passport_issue_place,
                                "passport_issue_date": data && data.details && data.details.passengers && data.details.passengers[0].passport_issue_date,
                                "ff_no": data && data.details && data.details.passengers && data.details.passengers[0].ff_no,
                                "visa_type": data && data.details && data.details.passengers && data.details.passengers[0].visa_type,
                                "city": data && data.details && data.details.passengers && data.details.passengers[0].city,
                                "country_code": data && data.details && data.details.passengers && data.details.passengers[0].country_code,
                                "country_name": data && data.details && data.details.passengers && data.details.passengers[0].country_name,
                                "nationality": data && data.details && data.details.passengers && data.details.passengers[0].nationality,
                                "contact_no": data && data.details && data.details.passengers && data.details.passengers[0].pax_title,
                                "email": data && data.details && data.details.passengers && data.details.passengers[0].email,
                                "address_line1": data && data.details && data.details.passengers && data.details.passengers[0].address_line1,
                                "address_line2": data && data.details && data.details.passengers && data.details.passengers[0].address_line2,
                                "is_lead_pax": data && data.details && data.details.passengers && data.details.passengers[0].is_lead_pax,
                                "gst_company_address": data && data.details && data.details.passengers && data.details.passengers[0].gst_company_address,
                                "gst_company_contact_number": data && data.details && data.details.passengers && data.details.passengers[0].gst_company_contact_number,
                                "gst_company_email": data && data.details && data.details.passengers && data.details.passengers[0].gst_company_email,
                                "gst_company_name": data && data.details && data.details.passengers && data.details.passengers[0].gst_company_name,
                                "gst_number": data && data.details && data.details.passengers && data.details.passengers[0].gst_number,
                                "fare": {
                                    "base_fare": data && data.details && data.details.fare.pax_title
                                },
                            }
                        ],
                        "segments": flightHelper.getSegmentsforRiya(data && data.details && data.details.segments),
                        "price_modified": {
                            "offered_fare": data && data.details && data.details.fare && data.details.fare.net_payable_amount,
                        },
                        "riya_booking_id": data.details && data.details.riya_booking_id ?  data.details.riya_booking_id :  data.details.transaction_no,
                        "booking_id": data &&data.booking_id,
                        "riya_pnr": data && data.details &&  data.details.riya_pnr,
                        "is_domestic": this.props.searchQuery.is_domestic,
                        "journey_type": this.props.searchQuery.journey_type,

                        //"pnr" : data.tbo_response.flight_itinerary.pnr,


                    }
                   // this.setState({ BookingDatas: bookingData })
                    this.props.riyaBookDetails(bookingData, successCb, errorCb)
                }
                let bookConfirmRequest = {
                    "riya_booking_id": data && data.details && data.details.riya_booking_id ? data.details.riya_booking_id : data.details.transaction_no,
                    "booking_id": data.booking_id,
                    "price_modified": {
                        "offered_fare": data && data.details && data.details.fare && data.details.fare.net_payable_amount,
                    },
                    "pnr": data && data.details && data.details.pnr,
                    "riya_pnr": data && data.details && data.details.riya_pnr
                }
                this.props.riyaBookConfirm(bookConfirmRequest, bookDetails, errorCb)

                // let validateData = {
                //     "booking_id": data.booking_id,
                //     "tripjack_booking_id": data && data.details && data.details.transaction_no
                // }
                // this.props.validateTripJackTicket(validateData, bookingConfirm, errorCb)
            } else if (this.state.confimationAction === 'release') {
                this.setState({
                    showCancelPopup: true
                });
            }
            this.setState({
                showConfirmationPopup : false,
                loading:true
            });
        }
        else{
            if(this.state.confimationAction === 'confirm'){
                this.props.confirmBooking(data);
            }else if(this.state.confimationAction === 'release'){
                this.releaseHoldPnr(data);
            }
            this.setState({
                showConfirmationPopup : false
            });
        }
    }
    
    componentDidMount(){
        let bookingDetails = sessionStorage.getItem("booking-details");
        let transactionNo;
        let pnr;
        let booking_status = "";
        let booking_type = "";
        let booking_id = "";
        let kafila_booking_id="";
        if(!this.props.location.state)
        {
            transactionNo = JSON.parse(bookingDetails).data.transaction_no;
            pnr = JSON.parse(bookingDetails).data.pnr;
            booking_status = JSON.parse(bookingDetails).data.booking_status;
            booking_type = JSON.parse(bookingDetails).data.booking_type;
            booking_id = JSON.parse(bookingDetails).data.booking_id;
            kafila_booking_id = JSON.parse(bookingDetails).data.kafila_booking_id;
        }
        else
        {
            transactionNo = this.props.location && this.props.location.state.data.transaction_no;
            pnr = this.props.location && this.props.location.state.data.pnr;
            booking_status = this.props.location && this.props.location.state.data.booking_status;
            booking_type = this.props.location && this.props.location.state.data.booking_type;
            booking_id  = this.props.location && this.props.location.state.data.booking_id;
            kafila_booking_id =  this.props.location && this.props.location.state.data.kafila_booking_id;
        }
        this.setState({
            transactionNo : transactionNo,
            pnr : pnr,
            booking_status : booking_status,
            kafila_booking_id: kafila_booking_id
        })
        if((booking_type === 'online' && (kafila_booking_id ==='' || kafila_booking_id === null)) || (this.props.location.state && this.props.location.state.pageUrl === "request-bookings")){
            let data = {
                "booking_id" : transactionNo,
                "tbo_booking_id" : transactionNo,
                "pnr" : pnr
            }
            if(this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.booking_from !== 'tripjack' && this.props.location.state.data.booking_from !== 'gofly' && this.props.location.state.data.booking_from !== 'satkar'  && this.props.location.state.data.booking_from !== 'riya'){
                this.props.getBookingDetails(data);
            }
        }
        if(booking_status === 'cancel_confirmed'){
            let bookingId = {
                //"tbo_booking_id" : transactionNo
                "booking_id" : booking_id
            }
            this.props.cancelRefund(bookingId);
        }
        let booking_info = {
            "booking_id" : booking_id
        }
        let FareRuleDetailsCredential = {}
        const successCb = (response) => {
            // response && response.map((bookingItem) => {
            //     bookingItem && bookingItem.bookings.map((detailItem) => {
            //         detailItem.details && detailItem.details.segments.map((segmentItem) => {
            //             segmentItem && segmentItem.map((item) =>{
            //                 if(detailItem.details.booking_from === 'tripjack'){
            //                         FareRuleDetailsCredential = {
            //                             "uid": '1234', 
            //                             "tripjack_id": (detailItem.details.transaction_no ? detailItem.details.transaction_no : this.state.transactionNo), 
            //                             "flowType" : "BOOKING_DETAIL", 
            //                             "origin": item.origin_code,
            //                             "destination": item.destination_code
            //                         }
            //                         this.props.getTripJackFlightFareRuleDetails(FareRuleDetailsCredential); 
            //                 }
            //                 if (detailItem.details.booking_from === 'airiq') {
            //                     FareRuleDetailsCredential = {
            //                         "trace_id": detailItem.details && detailItem.details.trace_id,
            //                         "result_index": "",
            //                         "segments": [
            //                             {
            //                                 "origin": item.origin_code,
            //                                 "destination": item.destination_code
            //                             }
            //                         ]
            //                     }
            //                     this.props.getAirIQFlightFareRuleDetails(FareRuleDetailsCredential);
            //                 }
            //                 if (detailItem.details.booking_from === 'gofly') {
            //                     FareRuleDetailsCredential = {
            //                         "trace_id": detailItem.details && detailItem.details.trace_id,
            //                         "result_index": "",
            //                         "segments": [
            //                             {
            //                                 "origin": item.origin_code,
            //                                 "destination": item.destination_code
            //                             }
            //                         ]
            //                     }
            //                     this.props.getGoflyFlightFareRuleDetails(FareRuleDetailsCredential);
            //                 } 
            //                 if (detailItem.details.booking_from === 'efly') {
            //                     FareRuleDetailsCredential = {
            //                         "trace_id": detailItem.details && detailItem.details.trace_id,
            //                         "result_index": "",
            //                         "segments": [
            //                             {
            //                                 "origin": item.origin_code,
            //                                 "destination": item.destination_code
            //                             }
            //                         ]
            //                     }
            //                     this.props.geteflyFlightFareRuleDetails(FareRuleDetailsCredential);
            //                 } 
            //                 if (detailItem.details.booking_from === 'satkar') {
            //                     FareRuleDetailsCredential = {
            //                         "trace_id": detailItem.details && detailItem.details.trace_id,
            //                         "result_index": "",
            //                         "segments": [
            //                             {
            //                                 "origin": item.origin_code,
            //                                 "destination": item.destination_code
            //                             }
            //                         ]
            //                     }
            //                     this.props.getSatkarFlightFareRuleDetails(FareRuleDetailsCredential);
            //                 }  
            //                 return null;
            //             })
            //             return null;
            //         })
            //         return null
            //     })
            //     return null;
            // })
            
        }
        const errorCb = () => {
        }
        this.props.bookingInfoAction(booking_info,successCb,errorCb); 
    }

    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }

    printTicket = (data) => {
        let printTicketData = {
            "jf_booking_id" : data.booking_id,
            "tbo_booking_id" : data.details.transaction_no,
            "pnr" : data.details.pnr,
            "booking_type" : data.details.booking_type
        }
        localStorage.setItem("print-ticket-data", JSON.stringify(printTicketData));
        window.open('/print-ticket', "_blank");
    }

    printInvoice = (data) => {
        let printInvoiceData = {
            "booking_id" : data.booking_id,
            "tbo_booking_id" : data.details.transaction_no,
            "pnr" : data.details.pnr,
            "booking_type" : data.details.booking_type
        }
        localStorage.setItem("print-invoice-data", JSON.stringify(printInvoiceData));
        window.open('/print-invoice', "_blank");
    }
    fareSummaryClick() {
        this.setState({fareSummaryPopup: !this.state.fareSummaryPopup});
    }
    //Show offered price and discount
    offeredPriceDisplay=()=>{
        this.setState({
            showOfferedPrice : this.state.showOfferedPrice ? false : true
        })
    }
    //View outbound booking
    viewInboundBooking = (transaction_no,pnr,booking_status,booking_type,booking_id) =>{
        let bookingData = {
            "transaction_no" : transaction_no,
            "pnr"            : pnr,
            "booking_status" : booking_status,
            "booking_type"   : booking_type,
            "booking_id"     : booking_id
        }
     
        let bookingDetails = {
            "data" : bookingData,
            "pageUrl" : 'booking-calendar'
        }
        sessionStorage.setItem("booking-details", JSON.stringify(bookingDetails));
        window.open('/booking', "_blank");
    }

    releaseHoldPnr = (values) => {
        let data = {
            "booking_id": values.booking_id,
            "tbo_booking_id": values.details.transaction_no,
            "source": values.details.source,
        }
        this.setState({
            releasedBooking: values.booking_id
        })
        const successCb = () => {
            window.scrollTo(0,0);            
            this.setState({
                releasePnrStatus: 'true'
            },() =>  setTimeout(
                function() {
                    this.setState({releasePnrStatus: false});
                }
                .bind(this),
                3000
            ))
        }
        const errorCb = () => {
            window.scrollTo(0,0);
            this.setState({
                releasePnrStatus: 'false'
            },() =>  setTimeout(
                function() {
                    this.setState({releasePnrStatus: false});
                }
                .bind(this),
                3000
            ))
        }
        this.props.releasePnr(data, successCb, errorCb);
    }

    releaseHoldTripJack = (values) => {
        let data = {
            "booking_id": values.booking_id,
            "tripjack_bookingId": values.details.transaction_no,
            "pnr": values.details.airline_pnr,
        }
        this.setState({
            releasedBooking: values.booking_id
        })
        const successCb = () => {
            window.scrollTo(0,0);            
            this.setState({
                releasePnrStatus: 'true'
            },() =>  setTimeout(
                function() {
                    this.setState({releasePnrStatus: false});
                }
                .bind(this),
                3000
            ))
        }
        const errorCb = () => {
            window.scrollTo(0,0);
            this.setState({
                releasePnrStatus: 'false'
            },() =>  setTimeout(
                function() {
                    this.setState({releasePnrStatus: false});
                }
                .bind(this),
                3000
            ))
        }
        this.props.releaseTripJack(data, successCb, errorCb);
    }

    render() {
        const { accountResponse,productResponse,tripJackFlightFareRuleDetails } = this.props;
        let bookingDetails = sessionStorage.getItem("booking-details");
        let pageUrl;
        
        if(!this.props.location.state)
        {
            pageUrl = JSON.parse(bookingDetails).pageUrl;
        }
        else
        {
            pageUrl = this.props.location && this.props.location.state.pageUrl;
        }
        let data = this.props.bookingInfoResponse.response && this.props.bookingInfoResponse.response[0].bookings[0];
        // if(this.props.calendarResponse.response && this.state.transactionNo ){
        //     for(let i = 0;i<this.props.calendarResponse.response.length ; i++){
        //         for(let j=0;j<this.props.calendarResponse.response[i].bookings.length;j++){
        //             let value = this.props.calendarResponse.response[i].bookings[j]
        //             if(value.details.transaction_no === this.state.transactionNo && value.details.pnr === this.state.pnr){
        //                 data = value;
        //             }
        //         }
        //     }
        // }
        let myaccountResponse = accountResponse.myAccountResponse.response;
        let totalAccountBalance = 0;
        if(myaccountResponse){
            if(parseInt(myaccountResponse.cash_balance) > 0){
                totalAccountBalance = parseInt(myaccountResponse.cash_balance) + parseInt(myaccountResponse.credit_limit);
            }else{
                totalAccountBalance = parseInt(myaccountResponse.credit_limit);
            }
        }
        let offeredFare = data && data.details && data.details.fare && data.details.fare.offered_fare;
        let showInsuffient = false
        if(totalAccountBalance < offeredFare){
            showInsuffient = true;
        }
        let activeLeftNavigation  = this.state.url;
        let adultPassenger = [];
        let childPassenger = [];
        let infantPassenger = [];
        let is_gst = false;
        let gst_details = [];
        if(data){
            for(let i = 0 ;i<data.details.passengers.length;i++ ){
                if(data.details.passengers[i].gst_number){
                    is_gst = true;
                    gst_details = {
                        "gst_number" : data.details.passengers[i].gst_number,
                        "gst_company_name" : data.details.passengers[i].gst_company_name,
                        "gst_company_email" : data.details.passengers[i].gst_company_email,
                        "gst_company_contact_number" : data.details.passengers[i].gst_company_contact_number,
                        "gst_company_address" : data.details.passengers[i].gst_company_address
                    }
                }
                if (data.details.passengers[i].pax_type === 'Adult')
                    adultPassenger.push(data.details.passengers[i]);
                if (data.details.passengers[i].pax_type === 'Child')
                    childPassenger.push(data.details.passengers[i]);
                if (data.details.passengers[i].pax_type === 'Infant')
                    infantPassenger.push(data.details.passengers[i]);
            }
        }
        // let loading = false;
        let fareQuote = data && data.details;
        let adultCount = 0;
        let adultFare = 0;
        let childCount = 0;
        let childFare = 0;
        let infantCount = 0;
        let infantFare = 0;
        
        if(fareQuote)
        {
            for(let i = 0;i<fareQuote.fare_breakdown.length;i++){
                let item = fareQuote.fare_breakdown[i];
                if (item.passenger_type === 1) {
                    adultCount = item.passenger_count;
                    adultFare = item.base_fare;
                } else if (item.passenger_type === 2) {
                    childCount = item.passenger_count;
                    childFare = item.base_fare;
                } else if (item.passenger_type === 3) {
                    infantCount = item.passenger_count;
                    infantFare = item.base_fare;
                }
            }
        }
        let totalBaseFare = Number(adultFare) + Number(childFare) + Number(infantFare);
        let totalTaxes = fareQuote && Number(fareQuote.fare.total_tax) + Number(fareQuote.fare.other_charges)+ Number(fareQuote.fare.other_tax) + Number(fareQuote.fare.cgst) + Number(fareQuote.fare.igst) + Number(fareQuote.fare.sgst) + Number(fareQuote.fare.service_charge);
        let seatAmt = fareQuote && fareQuote.fare.total_seat_charges;
        let mealAmt = fareQuote && fareQuote.fare.total_meal_charges;
        let baggageAmt = fareQuote && fareQuote.fare.total_baggage_charges;
        // let totalExtraFare = fareQuote && parseInt(baggageAmt) + parseInt(mealAmt) + parseInt(seatAmt);
        let grandTotal = fareQuote && fareQuote.fare.total_fare;
        let emptySsr = false;
        let userType = this.props.bookingInfoResponse.response && this.props.bookingInfoResponse.response[0].bookings[0].details.booking_from;

        const successCb = (response) => {
            this.props.myAccountAction();
            this.props.history.push({
                pathname: "/booking-confirm",
                state: {
                    data: response,
                    bookingType: 'confirm'
                }
            });
        }
        
    
        const errorCb = (response) =>{
            this.props.history.push({
                pathname:"/booking-error",
                state:{
                    page: 'booking',
                    pageUrl:this.props.location && this.props.location.state && this.props.location.state.pageUrl,
                    searchQuery: this.props.searchQuery,
                    response:(response && Object.keys(response) &&Object.keys(response).length > 0 ) ? JSON.stringify(response) : JSON.stringify({message:"something went wrong"}),
                }
            });
        }

        const successCb1 = (response) => {
            const makeTripJackBookDetailsCall = (apiCallCount) => {
                if (apiCallCount < 4 && response.booking_status === "ticket_pending") {
                  setTimeout(() => {
                    this.props.tripJackBookDetails(
                        this.state.BookingDatas,
                      makeTripJackBookDetailsCall.bind(null, apiCallCount + 1),
                      errorCb
                    );
                  }, 5000);
                } else {
                  successCb(response);
                }
              };
              makeTripJackBookDetailsCall(0);
        }
        
        let fairRuleSeatOriginDestination = '';
        let fairRuleOriginDestination = '';
        let fairRules = [];
        let flightFareRulesData = (fareQuote && fareQuote.farerules) ? (fareQuote && fareQuote.farerules) :  []
        if(Object.keys(flightFareRulesData).length > 0 && Object.keys(flightFareRulesData) && Object.keys(flightFareRulesData).length && Object.keys(flightFareRulesData)[0] !== '0')
        {
            fairRuleSeatOriginDestination = Object.keys(flightFareRulesData);
            if (this.props.location && this.props.location.state && this.props.location.state.bookingType === 'hold')
            {
                fairRuleOriginDestination = Object.keys(flightFareRulesData);
            }
            flightFareRulesData[fairRuleSeatOriginDestination] && flightFareRulesData[fairRuleSeatOriginDestination]['tfr'] && Object.keys(flightFareRulesData[fairRuleSeatOriginDestination]['tfr']).length > 0 &&
            Object.keys(flightFareRulesData[fairRuleSeatOriginDestination]['tfr']).forEach((fareRule, index)=>{
                if (fareRule !== "SEAT_CHARGEABLE") {
                    Object.keys(flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule]).length > 0 &&
                        Object.keys(flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule]).forEach((item, key) => {
                            let rulesArrKey = ""
                            if (flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].st !== undefined || flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].et !== undefined) {
                                rulesArrKey = (flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].st !== '' ?
                                    flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].st + " hr - " : '') +
                                    (flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].et !== '' ?
                                        (flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].et !== '8760' ?
                                            flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].et + ' hr' :
                                            (flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].et / 24) + ' days') : '');
                            } else {
                                rulesArrKey = flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].pp
                            }

                            if (!Array.isArray(fairRules[rulesArrKey])) {
                                fairRules[rulesArrKey] = [];
                            }
                            if (!Array.isArray(fairRules[rulesArrKey][fareRule])) {
                                fairRules[rulesArrKey][fareRule] = [];
                            }
                            fairRules[rulesArrKey][fareRule] = flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item];
                        });
                }

            })
        }

        function timeConversion(n) {
            var num = n;
            var hours = (num / 60);
            var rhours = Math.floor(hours);
            if (rhours.toString().length < 2)
                rhours = "0" + rhours;
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            if (rminutes.toString().length < 2)
                rminutes = "0" + rminutes;
            return rhours + 'h' + ':' + rminutes + 'm';
        }

        return (
            <React.Fragment>
                {/*nav-section Start*/}
                <AgentHeaderNavigation productResponseList={productResponse} {...this.props}/>
                {/*nav-section end*/}
                <section className="mid-wrapper">
                    <AgentCommonHeader {...this.props} />
                    <div className="my-account-info">
                        <div className="container">
                            <div className="my-account-sec">
                                <div className="my-account-outer">
                                    <AgentLeftBar handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} logoutAction={this.props.logoutAction}pageUrl={pageUrl}/>
                                    <div className="profile-detail-info booking-hold-block">
                                        <div className="edit-profile-bar edit-booking-bar">
                                            <div className="pull-right">{ /* eslint-disable-next-line */} 
                                                <a href="javascript:void(0);" className="btn btn-sky-blue fare-summmary-btn" onClick={this.fareSummaryClick.bind(this)}>{(this.state.fareSummaryPopup === true)?'Hide ':'Show '} Fare Summary</a>
                                            </div>

                                            <div className="fare-summery" style={{ display: (this.state.fareSummaryPopup === true)?'block':'none' }}>
                                                <div className="title-fare">
                                                    <h3 className="fare-title">Fare Summary
                                                        <span className="offer-amount-icon btn btn-blue net-fare-button-round" onClick={() => this.offeredPriceDisplay()}>{this.state.showOfferedPrice ? 'Hide Net Fare ' : 'Show Net Fare '} <i><img src="assets/images/offer-icon.svg" alt="" /></i></span>
                                                    </h3>
                                                </div>
                                                <div className="fare-outer">
                                                    <div className="cols">
                                                        <h4>Base FARE</h4>
                                                        <ul>
                                                            {
                                                                adultCount && adultCount !== 0
                                                                    ?<li> <span className="pull-left">{adultCount} Adult(s)</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(adultFare)}</span> </li>
                                                                    :''
                                                            }

                                                            {
                                                                 childCount && childCount !== 0
                                                                    ?<li> <span className="pull-left">{childCount} Child(s)</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(childFare)}</span> </li>
                                                                    :''
                                                            }

                                                            {
                                                                infantCount && infantCount !== 0
                                                                    ?<li> <span className="pull-left">{infantCount} Infant(s)</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(infantFare)}</span> </li>
                                                                    :''
                                                            }
                                                        </ul>
                                                        <div className="total-amount"> <span className="pull-left">Total Base Fare</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(totalBaseFare)}</span> </div>
                                                    </div>
                                                    <div className="cols">
                                                        <h4>TAXES &amp; SURCHARGES</h4>
                                                        <ul>
                                                            {
                                                                fareQuote && fareQuote.fare.total_tax !== 0
                                                                ?<li><span className="pull-left">Tax 
                                                                    </span><span className="pull-right"><i className="fa fa-rupee" aria-hidden="true"></i> {fareQuote && currencyFormat(fareQuote.fare.total_tax)}</span>
                                                                </li>
                                                                :''
                                                            }

                                                            {
                                                                fareQuote && fareQuote.fare.yq_tax  !== 0  
                                                                ?<li> <span className="pull-left">YQ Tax {(userType === 'tbo'  || userType === 'tripjack') && '(included)'}</span> <span className="pull-right"><i className="icon icon-rupee" /> {fareQuote && currencyFormat(fareQuote.fare.yq_tax)}</span> </li>
                                                                :''
                                                            }       

                                                            {
                                                                fareQuote && fareQuote.fare.other_charges && fareQuote.fare.other_charges  !== 0  
                                                                ?<li> <span className="pull-left">Other Charges</span> <span className="pull-right"><i className="icon icon-rupee" /> {fareQuote && currencyFormat(fareQuote.fare.other_charges)}</span> </li>
                                                                :''
                                                            }
                                                            {
                                                                fareQuote && fareQuote.fare.other_tax && fareQuote.fare.other_tax  !== 0  
                                                                ?<li> <span className="pull-left">Other Tax</span> <span className="pull-right"><i className="icon icon-rupee" /> {fareQuote && currencyFormat(fareQuote.fare.other_tax)}</span> </li>
                                                                :''
                                                            }
                                                            {
                                                                fareQuote && fareQuote.fare.service_charge && fareQuote.fare.service_charge  !== 0
                                                                ?<li> <span className="pull-left">Service Charge</span> <span className="pull-right"><i className="icon icon-rupee" /> {fareQuote && currencyFormat(fareQuote.fare.service_charge)}</span> </li>
                                                                :''
                                                            }
                                                            {
                                                                fareQuote && fareQuote.fare.customer_discount && fareQuote.fare.customer_discount !== 0
                                                                ?<li> <span className="pull-left">Discount</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(fareQuote.fare.customer_discount)}</span> </li>
                                                                :''
                                                            }
                                                            {
                                                                fareQuote 
                                                                ?<li> <span className="pull-left">GST</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(Number(fareQuote.fare.cgst) + Number(fareQuote.fare.igst) + Number(fareQuote.fare.sgst))}</span> </li>
                                                                :''
                                                            }
                                                            {/* {
                                                                fareQuote && fareQuote.fare.additional_txn_fee_pub && fareQuote.fare.additional_txn_fee_pub  !== 0  
                                                                ?<li> <span className="pull-left">Transaction Fee</span> <span className="pull-right"><i className="icon icon-rupee" /> {fareQuote && currencyFormat(fareQuote.fare.additional_txn_fee_pub)}</span> </li>
                                                                :''
                                                            } */}
                                                        </ul>
                                                        <div className="total-amount"> <span className="pull-left">Total Taxes &amp; Surcharges</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(totalTaxes)}</span> </div>
                                                    </div>
                                                    {
                                                        (baggageAmt !== 0 || mealAmt !== 0 || seatAmt !== 0) &&
                                                        <div className="cols">
                                                            <h4>Extra Fare</h4>
                                                            <ul>
                                                                {
                                                                    baggageAmt !== 0 &&
                                                                    <li> <span className="pull-left">Excess Baggage</span> <span className="pull-right"><i className="icon icon-rupee" />{baggageAmt}</span> </li>
                                                                }
                                                                {
                                                                    mealAmt !== 0 &&
                                                                    <li> <span className="pull-left">Meal</span> <span className="pull-right"><i className="icon icon-rupee" />{mealAmt}</span> </li>
                                                                }
                                                                {
                                                                    seatAmt !== 0 &&
                                                                    <li> <span className="pull-left">Seat Charges</span> <span className="pull-right"><i className="icon icon-rupee" />{seatAmt}</span> </li>
                                                                }
                                                            </ul>
                                                            {/* <div className="total-amount"> <span className="pull-left">Total Base Fare</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(totalExtraFare)}</span> </div> */}
                                                        </div>
                                                    }
                                                    <div className="grand-total"> <span className="pull-left">Grand Total</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(grandTotal)}</span> </div>
                                                    <div className="cols net-booking" style={{display:this.state.showOfferedPrice?'block':'none'}}>
                                                        <ul>
                                                            <li> <span className="pull-left">TDS</span> <span className="pull-right"><i className="icon icon-rupee" />{fareQuote && currencyFormat(fareQuote.fare.tds)}</span> </li>
                                                            <li> <span className="pull-left">Discount(-)</span> <span className="pull-right"><i className="icon icon-rupee" />{fareQuote && currencyFormat(Number(fareQuote.fare.agent_discount) + Number(fareQuote.fare.discount))}</span> </li>
                                                        </ul>
                                                        <div className="grand-total"> <span className="pull-left">Net Payable Amount</span> <span className="pull-right"><i className="icon icon-rupee" /> {fareQuote && currencyFormat(Math.round(fareQuote.fare.net_payable_amount))}</span> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            data && data.details ?
                                            <div className="profile-tabs-info">
                                                <div className="booking-hold-card">
                                                    <div className="step-content">
                                                        {
                                                            data && 
                                                            <h3 className="step-title"><span>{data.details.booking_status.replace(/_/g, ' ').toUpperCase()}</span> </h3>
                                                        }
                                                        {this.state.releasePnrStatus && this.state.releasePnrStatus === 'true' && <div className="alert alert-success">
                                                        <strong> <i className="fa fa-check-circle"></i>Your Booking is sucessfully released.</strong> <i className="fa fa-times" aria-hidden="true"></i> </div>}
                                                        {this.state.releasePnrStatus && this.state.releasePnrStatus === 'false' && <div className="alert alert-danger">
                                                        <strong><i className="fa fa-exclamation-triangle"></i>{(this.props.releasePnrDetail && this.props.releasePnrDetail.error && this.props.releasePnrDetail.error.message) || (this.props.releaseTripJackDetail && this.props.releaseTripJackDetail.error &&this.props.releaseTripJackDetail.error.message)}</strong> <i className="fa fa-times" aria-hidden="true" ></i></div>}
                                                        <div className="review-filght-step">
                                                            <div className="review-step">
                                                                <div className="review-step-title">
                                                                    <div className="cols pull-left"> <span>Flight detail</span> </div>
                                                                    <div className="cols pull-right print-ticket">
                                                                    {
                                                                        data && data.details.booking_status === 'ticket_confirmed' &&
                                                                            <React.Fragment>
                                                                                { /* eslint-disable-next-line */}  
                                                                                <a href="javascript:void(0);" onClick={() => this.printInvoice(data)}><i className="fa fa-print" aria-hidden="true"></i> Print Invoice</a>
                                                                                { /* eslint-disable-next-line */}  
                                                                                <a href="javascript:void(0);" onClick={() => this.printTicket(data)}><i className="fa fa-print" aria-hidden="true"></i> Print Ticket</a> 
                                                                                
                                                                            </React.Fragment>
                                                                    }
                                                                    {
                                                                        data && data.details.journey_type === 'outbound' && 
                                                                        <React.Fragment>
                                                                            { /* eslint-disable-next-line */}  
                                                                            <a href="javascript:void(0);" onClick={() => this.viewInboundBooking(data.details.inbound_transaction_no,data.details.inbound_pnr,data.details.booking_status,data.details.booking_type,data.details.booking_ref_no)}><i className="fa fa-print" aria-hidden="true"></i> View Inbound Booking</a>
                                                                        </React.Fragment>
                                                                    }
                                                                    {
                                                                        data && data.details.journey_type === 'inbound' && 
                                                                        <React.Fragment>
                                                                            { /* eslint-disable-next-line */}  
                                                                            <a href="javascript:void(0);" onClick={() => this.viewInboundBooking(data.details.inbound_transaction_no,data.details.inbound_pnr,data.details.booking_status,data.details.booking_type,data.details.booking_ref_no)}><i className="fa fa-print" aria-hidden="true"></i> View Outbound Booking</a>
                                                                        </React.Fragment>
                                                                    }
                                                                    {/* <Link to="/view-flight-booking" > My Bookings</Link>  */}
                                                                    </div>

                                                                </div>
                                                                <div className="review-step-deatil">
                                                                    <div className="step-deatil-outer">
                                                                    {
                                                                        data && data.details.segments.map((itemListing, keyLisitng) => {
                                                                            let stopData = flightHelper.getTicketFlightStops(itemListing);
                                                                            return (
                                                                                <div className="flight-details" key={keyLisitng}>
                                                                                    <div className="flight-date"> <i className="icon icon-flight" /> <span>{dateFormat(itemListing[0].departure_time, 'DD MMM YYYY, FDAY')}</span> </div>
                                                                                    <ul>
                                                                                        {
                                                                                            stopData.map((item, key) => {
                                                                                                let originTerminal = '';
                                                                                                let destinationTerminal = '';
                                                                                                if(item.origin_airport_terminal !== ''){
                                                                                                    originTerminal = <React.Fragment>- <strong>{item.origin_airport_terminal}</strong></React.Fragment>;
                                                                                                }
                                                                                                if(item.destination_airport_terminal !== '')
                                                                                                {
                                                                                                    destinationTerminal = <React.Fragment>- <strong>{item.destination_airport_terminal}</strong></React.Fragment>;
                                                                                                }
                                                                                                return (
                                                                                                    <li key={key}>
                                                                                                        <div className="flight-card">
                                                                                                            <div className="traveller-flight">
                                                                                                                <figure className="pull-left"><img src={item.airline_logo} alt="" /></figure>
                                                                                                                <div className="flight-dat"> 
                                                                                                                <span>{item.airline_name + ', ' + item.airline_code + " - " + item.flight_no}</span>
                                                                                                                <p>Flight Class - {item.fare_class}</p>
                                                                                                                {
                                                                                                                    (userType === 'tripjack' ||  userType === 'tbo' ) &&
                                                                                                                    <p>{flightHelper.getMyBookingAirlineOperatingCarrier(item)}</p>
                                                                                                                }
                                                                                                                {	
                                                                                                                    item.aircraft && <p>Aircraft - {item.aircraft}</p>	
                                                                                                                }
                                                                                                                </div>
                                                                                                                {/* <div className="flight-dat"> <span>{item.airline.airline_name+', '+item.airline.airline_code+" - "+item.airline.flight_number}</span>
                                                                                                                    <p>Aircraft - {item.craft}</p>
                                                                                                                </div> */}
                                                                                                            </div>
                                                                                                            <div className="flight-stops">
                                                                                                                <div className="flight-start">
                                                                                                                    <h3>{item.origin} </h3>
                                                                                                                    <span>{dateFormat(item.departure_time, 'FDAY, DD MMM, HH:MM')}</span>
                                                                                                                    <p>{item.origin_airport_name} {originTerminal}</p>
                                                                                                                </div>
                                                                                                                <div className="flight-direction flight-luggage flight-cabin flight-time">
                                                                                                                <div class="fl-mid-inner">
                                                                                                                        <ul>
                                                                                                                            {
                                                                                                                             
                                                                                                                               (userType !== 'tripjack' && userType !== 'tbo' &&userType !=='kafila' && userType !== 'gofly' && userType !== 'satkar' && userType !== 'riya') &&
                                                                                                                                <li>
                                                                                                                                    <i className="fa fa-clock-o" aria-hidden="true"></i> <span>{flightHelper.getDuration(itemListing, 'h')}</span>
                                                                                                                                </li>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                (userType === 'kafila') &&
                                                                                                                                 <li>
                                                                                                                                     <i className="fa fa-clock-o" aria-hidden="true"></i> <span>{timeConversion(item.duration)}</span>
                                                                                                                                 </li>
                                                                                                                             }
                                                                                                                            {
                                                                                                                                (userType === 'tripjack' || userType === 'gofly' || userType === 'satkar' || userType === 'riya') &&
                                                                                                                                <li>
                                                                                                                                    <i className="fa fa-clock-o" aria-hidden="true"></i> <span>{timeConversion(item.duration)} </span>
                                                                                                                                </li>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                userType === 'tbo' &&
                                                                                                                                <li>
                                                                                                                                    <i className="fa fa-clock-o" aria-hidden="true"></i> <span>{timeConversion(item.duration)} </span>
                                                                                                                                </li>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                item.cabin_baggage ?
                                                                                                                                    <li class="mid-meal"> <i class="material-icons">card_travel</i> <span>{item.cabin_baggage}</span> </li> : null
                                                                                                                            }
                                                                                                                            {
                                                                                                                                item.baggage ?
                                                                                                                                    <li> <i class="material-icons">shopping_cart</i> <span>{item.baggage}</span> </li> : null
                                                                                                                            }
                                                                                                                            {
                                                                                                                        <div className='time-col' style={{display:item.stop_point?"block":"none"}}>
                                                                                                                        <span style={{fontWeight:"bold",color:"black"}}>Technical Stop Via {item.stop_point}</span>
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                    <div className="flight-bar"><i className="icon icon-flight3" /></div>
                                                                                                                    {
                                                                                                                        item.cabin_class_name !== null &&
                                                                                                                            <div className='cabin-info'>
                                                                                                                                <strong>Cabin : </strong>
                                                                                                                                <span>{item.cabin_class_name}</span>
                                                                                                                            </div> 
                                                                                                                    }
                                                                                                                    
                                                                                                                </div>
                                                                                                                <div className="flight-start flight-landing">
                                                                                                                    <h3>{item.destination} </h3>
                                                                                                                    <span>{dateFormat(item.arrival_time, 'FDAY, DD MMM, HH:MM')}</span>
                                                                                                                    <p>{item.destination_airport_name} {destinationTerminal}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-step">
                                                                <div className="review-step-title">
                                                                    <div className="cols pull-left"> <span>Passenger Details</span> </div>
                                                                    <div className="cols pull-right">  </div>
                                                                </div>
                                                                <div className= "review-step-deatil">
                                                                    <div className="step-deatil-outer">
                                                                        <div className="passenger-information">
                                                                            <div className="passenger-fl-info">
                                                                            {
                                                                                data && data.details.passengers.map((item,index) => {
                                                                                    return (
                                                                                    <div className="cols" key={index}>
                                                                                        <div className="adult-title"><span>Passenger {index+1}</span> <small>({item.pax_type})</small></div>
                                                                                            <ul className="psg-list">
                                                                                                <li>
                                                                                                    <span>Name:</span>
                                                                                                    <small>{item.pax_title+' '+jsUcfirst(item.first_name)+' '+jsUcfirst(item.last_name)}</small>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <span>Gender:</span>
                                                                                                    <small>{item.pax_title === 'Mrs' || item.pax_title === 'Miss' || item.pax_title === 'Ms' ? 'Female' : 'Male'}</small>
                                                                                                </li>
                                                                                                <li  style={{ display: (item.is_lead_pax === 1 && item.contact_no !== "" && item.contact_no)?'block':'none' }}>
                                                                                                    <span>Mobile No:</span>
                                                                                                    <small>{item.contact_no}</small>
                                                                                                </li>
                                                                                                <li style={{ display: (item.dateofbirth)?'block':'none' }}>
                                                                                                    <span>DOB:</span>
                                                                                                    <small>{dateFormat(item.dateofbirth,'DD MMM YYYY')}</small>
                                                                                                </li>
                                                                                                <li  style={{ display: (item.is_lead_pax === 1 && item.email !== "" && item.email)?'block':'none' }}>
                                                                                                    <span>Email:</span>
                                                                                                    <small>{item.email}</small>
                                                                                                </li>
                                                                                                <li style={{ display: (item.is_lead_pax === 1 && item.address_line1)?'block':'none' }}>
                                                                                                    <span>Address:</span>
                                                                                                    <small>{item.address_line1+''+(item.address_line2?item.address_line2:'')}</small>
                                                                                                </li>
                                                                                                {
                                                                                                   data.details.booking_type === 'offline' && item.baggage && 
                                                                                                    <li>
                                                                                                       <span>Baggage:</span>
                                                                                                        {
                                                                                                            item.baggage.map((item,index) => {
                                                                                                                return <small key={index}>{item.weight+' kg'}</small> 
                                                                                                            }) 
                                                                                                        }
                                                                                                    </li>
                                                                                                }
                                                                                                {
                                                                                                   data.details.booking_type === 'offline' && item.meal && 
                                                                                                    <li>
                                                                                                       <span>Meal:</span>
                                                                                                        {
                                                                                                            item.meal.map((item,index) => {
                                                                                                                return <small key={index}>{item.ssr_code+' Meal'}</small> 
                                                                                                            }) 
                                                                                                        }
                                                                                                    </li>
                                                                                                }
                                                                                                {
                                                                                                   data.details.booking_type === 'offline' && item.seat && 
                                                                                                    <li>
                                                                                                       <span>Seat:</span>
                                                                                                        {
                                                                                                            item.seat.map((item,index) => {
                                                                                                                return <small key={index}>{item.ssr_code}</small> 
                                                                                                            }) 
                                                                                                        }
                                                                                                    </li>
                                                                                                }
                                                                                            </ul>
                                                                                        </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>        
                                                            { is_gst &&
                                                            <div className="review-step">
                                                                <div className="review-step-title">
                                                                    <div className="cols pull-left"> <span>GST Details</span> </div>
                                                                    <div className="cols pull-right"> </div>
                                                                </div>
                                                                <div className="review-step-deatil">
                                                                    <div className="step-deatil-outer">
                                                                        <div className="travellers-details">
                                                                            <div className="travellers">
                                                                                <div className="travellers-list">
                                                                                    <div className="cols">
                                                                                        <ul>
                                                                                            <li><span>GST Number :</span></li>
                                                                                            <li><span>GST Company Name :</span></li>
                                                                                            <li><span>GST Email :</span></li>   
                                                                                            <li><span>GST Contact Number :</span></li>   
                                                                                            <li><span>GST Address :</span></li>   
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="cols">
                                                                                        <ul>
                                                                                            <li>{gst_details["gst_number"]}</li>
                                                                                            <li dangerouslySetInnerHTML={{ __html : gst_details["gst_company_name"]}}></li>
                                                                                            <li>{gst_details["gst_company_email"]}</li>
                                                                                            <li>{gst_details["gst_company_contact_number"]}</li>
                                                                                            <li>{gst_details["gst_company_address"]}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="cols">
                                                                                        <ul>
                                                                                            <li></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }                   
                                                        { this.props.cancelRefundDetails.response && this.props.cancelRefundDetails.response.cancellation_status === 7 &&
                                                            <div className="review-step">
                                                                <div className="review-step-title">
                                                                    <div className="cols pull-left"> <span>Cancellation detail</span> </div>
                                                                    <div className="cols pull-right"> </div>
                                                                </div>
                                                                <div className="review-step-deatil">
                                                                    <div className="step-deatil-outer">
                                                                        <div className="travellers-details">
                                                                            <div className="travellers">
                                                                                <div className="travellers-list">
                                                                                    <div className="cols">
                                                                                        <ul>
                                                                                            <li>Cancellation Charges</li>
                                                                                            <li>Amount Refund</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="cols">
                                                                                        <ul>
                                                                                            <li>₹{currencyFormat(this.props.cancelRefundDetails.response.total_cancellation_charges)}</li>
                                                                                            <li>₹{currencyFormat(this.props.cancelRefundDetails.response.refunded_amount)}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="cols">
                                                                                        <ul>
                                                                                            <li></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            data && data.details.booking_type === 'online' &&
                                                            <div className="review-step">
                                                                <div className="review-step-title">
                                                                    <div className="cols pull-left"> <span>Fare Rules</span> </div>
                                                                    <div className="cols pull-right"> </div>
                                                                </div>

                                                                <div className="review-step-deatil">
                                                                    {
                                                                        userType === 'kafila' && Array.isArray(flightFareRulesData) &&
                                                                        <div className="step-deatil-outer">
                                                                            <div className="fare-rule-info">
                                                                                <PerfectScrollbar>
                                                                                    <div className="inner-fare-block">
                                                                                        <React.Fragment>
                                                                                            <div className="table-outer">
                                                                                                <div className="table-container content-block-cms">
                                                                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th width="15%">Time Frame</th>
                                                                                                                <th style={{ minWidth: '100px' }}>Cancellation Fee</th>
                                                                                                                <th>Date Changes Fee</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                Array.isArray(flightFareRulesData) &&
                                                                                                                flightFareRulesData && flightFareRulesData.map((item, index) => {
                                                                                                                    return (
                                                                                                                        <tr key={index}>
                                                                                                                            <td>{item && item.time_frame}</td>
                                                                                                                            <td>{item && item.cancellation_fee} </td>
                                                                                                                            <td> {item && item.date_change_fee}</td>
                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </table>

                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    </div>
                                                                                </PerfectScrollbar>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        userType === 'kafila' && !Array.isArray(flightFareRulesData) &&
                                                                        <div className="step-deatil-outer">
                                                                            <p className='clear_both'></p>
                                                                            <div className='content-block-cms'>
                                                                                <div dangerouslySetInnerHTML={{ __html: flightFareRulesData }} />
                                                                            </div>
                                                                        </div>
                                                                    }   
                                                                    {
                                                                        userType === 'airiq' && Array.isArray(flightFareRulesData) &&
                                                                        flightFareRulesData && flightFareRulesData.map((item, index) => {
                                                                            return (
                                                                                <div className="step-deatil-outer" key={index}>
                                                                                    {
                                                                                        item.origin && item.destination &&
                                                                                        <p>{item.origin}-{item.destination}</p>
                                                                                    }
                                                                                    <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        userType === 'airiq' && !Array.isArray(flightFareRulesData) &&
                                                                        <div className="step-deatil-outer">
                                                                            <p className='clear_both'></p>
                                                                            <div className='content-block-cms'>
                                                                                <div dangerouslySetInnerHTML={{ __html: flightFareRulesData }} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        userType === 'gofly' && Array.isArray(flightFareRulesData) &&
                                                                        flightFareRulesData && flightFareRulesData.map((item, index) => {
                                                                            return (
                                                                                <div className="step-deatil-outer" key={index}>
                                                                                    {
                                                                                        item.origin && item.destination &&
                                                                                        <p>{item.origin}-{item.destination}</p>
                                                                                    }
                                                                                    <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        userType === 'gofly' && !Array.isArray(flightFareRulesData) &&
                                                                        <div className="step-deatil-outer">
                                                                            <p className='clear_both'></p>
                                                                            <div className='content-block-cms'>
                                                                                <div dangerouslySetInnerHTML={{ __html: flightFareRulesData }} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        userType === 'efly' && Array.isArray(flightFareRulesData) &&
                                                                        flightFareRulesData && flightFareRulesData.map((item, index) => {
                                                                            return (
                                                                                <div className="step-deatil-outer" key={index}>
                                                                                    {
                                                                                        item.origin && item.destination &&
                                                                                        <p>{item.origin}-{item.destination}</p>
                                                                                    }
                                                                                    <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        userType === 'efly' && !Array.isArray(flightFareRulesData) &&
                                                                        <div className="step-deatil-outer">
                                                                            <p className='clear_both'></p>
                                                                            <div className='content-block-cms'>
                                                                                <div dangerouslySetInnerHTML={{ __html: flightFareRulesData }} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        userType === 'satkar' && Array.isArray(flightFareRulesData) &&
                                                                        flightFareRulesData && flightFareRulesData.map((item, index) => {
                                                                            return (
                                                                                <div className="step-deatil-outer" key={index}>
                                                                                    {
                                                                                        item.origin && item.destination &&
                                                                                        <p>{item.origin}-{item.destination}</p>
                                                                                    }
                                                                                    <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        userType === 'satkar' && !Array.isArray(flightFareRulesData) &&
                                                                        <div className="step-deatil-outer">
                                                                            <p className='clear_both'></p>
                                                                            <div className='content-block-cms'>
                                                                                <div dangerouslySetInnerHTML={{ __html: flightFareRulesData }} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                     {
                                                                        userType === 'riya' && Array.isArray(flightFareRulesData) &&
                                                                        flightFareRulesData && flightFareRulesData.map((item, index) => {
                                                                            return (
                                                                                <div className="step-deatil-outer" key={index}>
                                                                                     <PerfectScrollbar>
                                                                                    {
                                                                                        item.origin && item.destination &&
                                                                                        <p>{item.origin}-{item.destination}</p>
                                                                                    }
                                                                                    <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                                                                    </PerfectScrollbar>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        userType === 'riya' && !Array.isArray(flightFareRulesData) &&
                                                                        <div className="step-deatil-outer">
                                                                            <PerfectScrollbar>
                                                                            <p className='clear_both'></p>
                                                                            <div className='content-block-cms'>
                                                                                <div dangerouslySetInnerHTML={{ __html: flightFareRulesData }} />
                                                                            </div>
                                                                            </PerfectScrollbar>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (userType === 'gofly' || userType === 'efly' || userType === 'satkar' || userType === 'airiq' || userType === 'riya') && Object.keys(flightFareRulesData).length === 0 &&
                                                                        <div className="step-deatil-outer">
                                                                            <div className="fare-rule-info">
                                                                                <div className="inner-fare-block">
                                                                                    <p>
                                                                                        <br />Fare Rules not available.<br />
                                                                                        <p><br />The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                                                                        <p>Mentioned Charges are Per Pax Per Sector</p>
                                                                                        <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.</p>
                                                                                        <p>For more information Please contact our helpdesk.</p>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        userType === 'tripjack' &&
                                                                        <div className="step-deatil-outer">
                                                                            <div className="fare-rule-info">
                                                                            {
                                                                                flightFareRulesData && Object.keys(flightFareRulesData) && Object.keys(flightFareRulesData)[0] === '0' && Object.keys(flightFareRulesData).length !== 0 ?
                                                                                    (
                                                                                        flightFareRulesData.map(function (data, index) {
                                                                                            let fareRulesDetails = data.fare_rule_detail.replace(/\r\n\t/g, '');
                                                                                            return (
                                                                                                <React.Fragment key={index}>
                                                                                                    <h3 className='clear_both'>{data.origin} - {data.destination}</h3>
                                                                                                    <div className='content-block-cms'>
                                                                                                        <div dangerouslySetInnerHTML={{ __html: fareRulesDetails }} />
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })
                                                                                    ) :
                                                                                    (
                                                                                        <PerfectScrollbar>
                                                                                            <div className="inner-fare-block">
                                                                                                {
                                                                                                    Object.keys(fairRules).length === 0 &&
                                                                                                    <p>
                                                                                                        <br />Fare Rules not available.<br />
                                                                                                        <p><br />The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                                                                                        <p>Mentioned Charges are Per Pax Per Sector</p>
                                                                                                        <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.</p>
                                                                                                        <p>For more information Please contact our helpdesk.</p>
                                                                                                    </p>
                                                                                                }
                                                                                                {
                                                                                                    Object.keys(fairRules).length > 0 &&
                                                                                                    <React.Fragment>
                                                                                                        <p className='clear_both'>{fairRuleOriginDestination}</p>
                                                                                                        <div className="table-outer">
                                                                                                            <div className="table-container content-block-cms">
                                                                                                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th width="15%">Time Frame</th>
                                                                                                                            <th style={{ minWidth: '100px' }}>Cancellation Fee</th>
                                                                                                                            <th>Date Changes Fee</th>
                                                                                                                            <th>No Show</th>
                                                                                                                            {/* <th width="16%">Seat Chargeable</th> */}
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            Object.keys(fairRules).length > 0 && Object.keys(fairRules).map((fareRule, index) => {
                                                                                                                                let fairRuleCancellationFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['policyInfo'] ? fairRules[fareRule]['CANCELLATION']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                                                                                                let fairRuleNoShow = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['policyInfo'] ? fairRules[fareRule]['NO_SHOW']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                                                                                                let fairRuleDateChange = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['policyInfo'] ? fairRules[fareRule]['DATECHANGE']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                                                                                                // let fairRuleSeatChargeable = fairRules[fareRule]['SEAT_CHARGEABLE'] && fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'] ? fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                                                                                                let fairRuleCancellationFeeAmount = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>' + fairRules[fareRule]['CANCELLATION']['amount'] : '';
                                                                                                                                let fairRuleCancellationFeeAdFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>' + fairRules[fareRule]['CANCELLATION']['additionalFee'] : '';
                                                                                                                                let fairRuleDateChangeAmount = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>' + fairRules[fareRule]['DATECHANGE']['amount'] : '';
                                                                                                                                let fairRuleDateChangeAdFee = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>' + fairRules[fareRule]['DATECHANGE']['additionalFee'] : '';
                                                                                                                                // let fairRuleNoShowDateSt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['st'] ? fairRules[fareRule]['NO_SHOW']['st'].replaceAll('__nls__', '<br>') + ' hr' + ' - ' : '';
                                                                                                                                // let fairRuleNoShowDateEt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['et'] ? (fairRules[fareRule]['NO_SHOW']['et'].replaceAll('__nls__', '<br>')/24) + ' days': '';

                                                                                                                                fairRuleCancellationFee = (fairRuleCancellationFee === 'Not Applicable') || (fairRuleCancellationFee === 'CANCELLATION Not Available') || (fairRuleCancellationFee === "Not Refundable") ? fairRuleCancellationFee : fairRuleCancellationFeeAmount + fairRuleCancellationFeeAdFee && "  " + fairRuleCancellationFeeAmount + " " + fairRuleCancellationFee;
                                                                                                                                fairRuleDateChange = fairRuleDateChange === 'Not Applicable' || (fairRuleDateChange === "Not Refundable") ? fairRuleDateChange : fairRuleDateChangeAmount + fairRuleDateChangeAdFee && "  " + fairRuleDateChangeAmount + " " + fairRuleDateChange;

                                                                                                                                return (<tr key={index}>
                                                                                                                                    <td><div dangerouslySetInnerHTML={{ __html: fareRule.replaceAll("_", " ") }} /></td>
                                                                                                                                    <td><div dangerouslySetInnerHTML={{ __html: fairRuleCancellationFee }} /> </td>
                                                                                                                                    <td><div dangerouslySetInnerHTML={{ __html: fairRuleDateChange }} /> </td>
                                                                                                                                    <td><div dangerouslySetInnerHTML={{ __html: fairRuleNoShow }} /></td>
                                                                                                                                    {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                                                                                                                </tr>)
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                                <br />
                                                                                                                <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                                                                                                <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                                                                                                <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                                                                                                <p>For more information Please contact our helpdesk.</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                            </div>
                                                                                        </PerfectScrollbar>
                                                                                    )
                                                                            }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    
                                                                    <div className="step-deatil-outer">
                                                                       
                                                                                                                                       {
                                                                        this.props.bookingDetails.loading 
                                                                        ?
                                                                        <div className="loader relative-loader" style={{display: 'block'}}>
                                                                        <div className="loader-content"><div className="loader-ring blue-ring"> 
                                                                        <span/> </div></div></div>
                                                                        : userType === "tbo" &&
                                                                        <PerfectScrollbar>
                                                                        <div className="fare-rule-info">
                                                                            <div className="inner-fare-block">
                                                                            {
                                                                                this.props.bookingDetails.response && this.props.bookingDetails.response.flight_itinerary.farerules.map((item,index) => {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            <p>{item.airline}:{item.origin}-{item.destination}<br/></p>
                                                                                            <div dangerouslySetInnerHTML={{ __html: item.farerule_detail }} />
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                            </div>
                                                                        </div>
                                                                        </PerfectScrollbar>
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }  
                                                        {
                                                         this.props.bookingDetails.response &&   data && data.details.booking_type === 'online' && userType !== 'kafila' &&
                                                            <div className="review-step">
                                                                <div className="review-step-title">
                                                                    <div className="cols pull-left"> <span>SSR details</span> </div>
                                                                    <div className="cols pull-right"></div>
                                                                </div>
                                                                <div className="review-step-deatil">
                                                                    <div className="step-deatil-outer">
                                                                        <div className="flight-information">
                                                                            <div className="inner-fl-info">
                                                                                <table className="fl-book-info">
                                                                                    <thead>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            this.props.bookingDetails.response && 
                                                                                            this.props.bookingDetails.response.flight_itinerary.passenger.map((itemOuter,indexOuter) => {
                                                                                                if(itemOuter.ssr.length === 0){
                                                                                                    emptySsr = true;   
                                                                                                }else{
                                                                                                    emptySsr = false;   
                                                                                                }
                                                                                                return itemOuter.ssr.map((item,index) => {
                                                                                                    return (
                                                                                                        <tr key={index}>
                                                                                                            <td>{itemOuter.title+' '+itemOuter.first_name+' '+itemOuter.last_name}</td>
                                                                                                            <td>{item.ssr_code}</td>
                                                                                                            <td>{item.detail}</td>
                                                                                                            <td>{item.status === 1 ? 'Confirmed':'Pending'}</td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            })
                                                                                        }
                                                                                        {
                                                                                        emptySsr &&
                                                                                        <tr>
                                                                                                <td>SSR information is not available</td>      
                                                                                            </tr>
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                            {
                                                                data && data.details.booking_status === 'hold_confirmed' && data.details.booking_type === 'online' &&
                                                                <React.Fragment>
                                                                    {showInsuffient && (
                                                                        <span className="insufficient_balance">
                                                                            Sorry, insufficient balance in your account. Booking can't proceed.
                                                                        </span>
                                                                    )}
                                                                    {
                                                                                data && data.details.booking_from === "tripjack" && this.state.releasePnrStatus!==false &&
                                                                                /* eslint-disable-next-line */
                                                                                <a href="/" className={`btn btn-blue loading-btn ${showInsuffient ? 'btn-disabled' : ''}`} style={{ marginRight: '10px',pointerEvents:showInsuffient ? "none" : "" }}
                                                                                    onClick={(e) =>{
                                                                                        e.preventDefault();
                                                                                        if (!showInsuffient) {
                                                                                            this.handleConfirmationPopup(true, 'confirm');
                                                                                        }
                                                                                    }}><span>Confirm Booking</span>


                                                                                    {
                                                                                        this.state.loading===true //this.props.ticketBookingData.loading
                                                                                            ?
                                                                                            <div className="loader-hotel">
                                                                                                <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>
                                                                                                <div className="overlay-loader show"></div>
                                                                                            </div>
                                                                                            : ''
                                                                                    }
                                                                                </a>
                                                                    }
                                                                    {
                                                                                data && data.details.booking_from !== "tripjack" &&
                                                                                /* eslint-disable-next-line */
                                                                                <a href="/" className={`btn btn-blue loading-btn ${showInsuffient ? 'btn-disabled' : ''}`} style={{ marginRight: '10px',pointerEvents:showInsuffient ? "none" : "" }}
                                                                                    onClick={(e) =>{
                                                                                        e.preventDefault();
                                                                                        if (!showInsuffient) {
                                                                                            this.handleConfirmationPopup(true, 'confirm');
                                                                                        }
                                                                                    }}><span>Confirm Booking</span>


                                                                                    {
                                                                                        data && data.details.booking_from === 'riya' && this.state.loading === true ?
                                                                                            <div className="loader-hotel">
                                                                                                <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>
                                                                                                <div className="overlay-loader show"></div>
                                                                                            </div>
                                                                                            : ''
                                                                                    }
                                                                                    {
                                                                                        data && data.details.booking_from !== 'riya' && this.props.ticketBookingData.loading
                                                                                            ?
                                                                                            <div className="loader-hotel">
                                                                                                <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>
                                                                                                <div className="overlay-loader show"></div>
                                                                                            </div>
                                                                                            : ''
                                                                                    }
                                                                                </a>
                                                                    }
                                                                    {
                                                                        this.state.releasePnrStatus !== true && (data && data.details.booking_from !== 'tripjack') && 
                                                                        /* eslint-disable-next-line */
                                                                        <a href="javascript:void(0);" className={`btn btn-grey loading-btn`}  
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            if (data && data.details.booking_from === 'riya') {
                                                                                this.setState({ showCancelPopup: true });
                                                                            } else {
                                                                                this.handleConfirmationPopup(data,'release');
                                                                            }
                                                                        }}>
                                                                            <span>Release</span>
                                                                        {
                                                                            this.props.releasePnrDetail.loading
                                                                                ?
                                                                                <div className="loader-hotel">
                                                                                    <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>
                                                                                    <div className="overlay-loader show"></div>
                                                                                </div>
                                                                                :''
                                                                        }
                                                                        </a>
                                                                    }
                                                                      { data && data.details && data.details.booking_from === 'riya' && (
                                                                            <div className={`popup-crad confirmation-popup ${this.state.showCancelPopup ? 'show' : ''}`}>      
                                                                                <div className="popup-outer">
                                                                                    <div className="popup-content">
                                                                                        <div className="popup-head">
                                                                                            <h2>Release</h2>
                                                                                            <div className="popup-close">
                                                                                                <span
                                                                                                    className="icon icon-cross"
                                                                                                    onClick={() => this.setState({ showCancelPopup: false })}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="popup-body popup-scroll">
                                                                                            <div className="inner-confirmation">
                                                                                                <p>For Release hold booking. Please contact the Administrator.</p>
                                                                                                <div className="popup-footer">
                                                                                                    <button
                                                                                                        className="btn btn-orange"
                                                                                                        onClick={() => this.setState({ showCancelPopup: false })}
                                                                                                    >
                                                                                                        Close
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="popup-overlay" />
                                                                            </div>
                                                                        )}
                                                                    {
                                                                        this.state.releasePnrStatus !== false && (data && data.details.booking_from === 'tripjack') && 
                                                                        <a href="javascript:void(0);" className={`btn btn-grey loading-btn`}  
                                                                        onClick={() => 
                                                                            this.handleConfirmationPopup(data,'release')
                                                                        }>
                                                                            <span>Release</span>
                                                                        {
                                                                            this.props.releaseTripJackDetail.loading
                                                                                ?
                                                                                <div className="loader-hotel">
                                                                                    <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>
                                                                                    <div className="overlay-loader show"></div>
                                                                                </div>
                                                                                :''
                                                                        }
                                                                        </a>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           :<div className="loader relative-loader" style={{"display":"block"}}>
                                                    <div className="loader-content">
                                                        <div className="loader-ring blue-ring"> 
                                                            <span></span> 
                                                        </div>
                                                    </div>
                                                </div>
                                        } 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`popup-crad confirmation-popup ${this.state.showConfirmationPopup ? 'show' : ''}`}>
                        <div className="popup-outer">
                            <div className="popup-content">
                                <div className="popup-head">
                                    <h2>Booking Confirmation</h2>
                                    <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false, null)} /></div>
                                </div>
                                <div className="popup-body popup-scorll">
                                    <div className="inner-confirmation">
                                        <p>Are you sure you want to continue?</p>
                                        <div className="popup-footer">
                                        <button className="btn btn-orange" onClick={() => this.handleConfimationAction(data, successCb, errorCb,successCb1)}>Yes</button>
                                            <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false, null)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup-overlay" />
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default AgentBookingComponent