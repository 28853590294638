import {
    FLIGHT_SEARCH_START,
    FLIGHT_SEARCH_OK,
    ITINERARIES_EMAIL_START,
    ITINERARIES_EMAIL_OK,
    ITINERARIES_EMAIL_ERR,
    FLIGHT_FARE_RULE_DETAILS_START,
    FLIGHT_FARE_RULE_DETAILS_OK,
    FLIGHT_FARE_RULE_DETAILS_ERR,
    ROUND_FLIGHT_FARE_RULE_DETAILS_START,
    ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
    ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,

    TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_START,
    TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_OK,
    TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_ERR,
    TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_START,
    TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
    TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,

    KAFILA_FLIGHT_FARE_RULE_DETAILS_START,
    KAFILA_FLIGHT_FARE_RULE_DETAILS_OK,
    KAFILA_FLIGHT_FARE_RULE_DETAILS_ERR,
    KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_START,
    KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
    KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,

    AIRIQ_FLIGHT_FARE_RULE_DETAILS_START,
    AIRIQ_FLIGHT_FARE_RULE_DETAILS_OK,
    AIRIQ_FLIGHT_FARE_RULE_DETAILS_ERR,
    AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_START,
    AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
    AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,

    GOFLY_FLIGHT_FARE_RULE_DETAILS_START,
    GOFLY_FLIGHT_FARE_RULE_DETAILS_OK,
    GOFLY_FLIGHT_FARE_RULE_DETAILS_ERR,
    GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_START,
    GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
    GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,

    EFLY_FLIGHT_FARE_RULE_DETAILS_START,
    EFLY_FLIGHT_FARE_RULE_DETAILS_OK,
    EFLY_FLIGHT_FARE_RULE_DETAILS_ERR,
    EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_START,
    EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
    EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,

    SATKAR_FLIGHT_FARE_RULE_DETAILS_START,
    SATKAR_FLIGHT_FARE_RULE_DETAILS_OK,
    SATKAR_FLIGHT_FARE_RULE_DETAILS_ERR,
    SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_START,
    SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
    SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,

    RIYA_FLIGHT_FARE_RULE_DETAILS_START,
    RIYA_FLIGHT_FARE_RULE_DETAILS_OK,
    RIYA_FLIGHT_FARE_RULE_DETAILS_ERR,
    RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_START,
    RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
    RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,

    FLIGHT_PRICE_DETAILS_START,
    FLIGHT_PRICE_DETAILS_OK,
    FLIGHT_PRICE_DETAILS_ERR,
    ADVANCE_SEARCH_UPDATED,
    REINITIALIZE_FLIGHT_PRICE_DETAILS_START,
    FLIGHT_SEARCH_KAFILA_START,
    FLIGHT_SEARCH_KAFILA_OK,
    FLIGHT_SEARCH_TRIPJACK_START,
    FLIGHT_SEARCH_TRIPJACK_OK,
    FLIGHT_SEARCH_AIRIQ_START,
    FLIGHT_SEARCH_AIRIQ_OK,
    SORT_RESULTS,
    SET_FLIGHT_FARE_RULE_DETAILS,
    SET_TRIP_JACK_FLIGHT_FARE_RULE_DETAILS,
    SET_KAFILA_FLIGHT_FARE_RULE_DETAILS,
    SET_AIRIQ_FLIGHT_FARE_RULE_DETAILS,
    SET_GOFLY_FLIGHT_FARE_RULE_DETAILS,
    SET_SATKAR_FLIGHT_FARE_RULE_DETAILS,
    SET_RIYA_FLIGHT_FARE_RULE_DETAILS,
    FLIGHT_SEARCH_GOFLY_START,
    FLIGHT_SEARCH_GOFLY_OK,
    FLIGHT_SEARCH_EFLY_START,
    FLIGHT_SEARCH_EFLY_OK,
    SET_EFLY_FLIGHT_FARE_RULE_DETAILS,
    FLIGHT_SEARCH_SATKAR_START,
    FLIGHT_SEARCH_SATKAR_OK,
    FLIGHT_SEARCH_SATKAR_ERR,
    FLIGHT_SEARCH_RIYA_START,
    FLIGHT_SEARCH_RIYA_OK
} from '../../actions/flight/flightSearchDetailsAction';

import {getSearchDetailsFormattedResults} from '../../shared/flightHelper'

export const initialState = {
    loading: true,
    searchResult: null,
    tboSearched :false,
    kafilaSearched :false,
    tripJackSearched :false,
    airIQSearched :false,
    goflySearched :false,
    eflySearched:false,
    satkarSearched:false,
    riyaSearched: false,
    is_kafila_user : localStorage.getItem('is_kafila_user'),
    advanceSearchFilter: [
        {
            stops: [], 
            departTime: [], 
            fareType: [], 
            airlines: [], 
            layover: [],
            segmentStops: [0],
            flight_number : null
        },
        {
            stops: [], 
            departTime: [], 
            fareType: [], 
            airlines: [], 
            layover: [],
            segmentStops: [0],
            flight_number : null
        }
    ],
    resultSorting: { 
        sortType: "",
        sortBy : "",
        inboundSortType:"",
        inboundSortBy : "",
    },
    ItinerariesMail : {
        response : null,
        loading  : false
    },
    flightFareRuleDetails : {
        response : null,
        loading  : false
    },
    roundFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    kafilaFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    kafilaRoundFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    tripJackFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    tripJackRoundFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    airIQFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    airIQRoundFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    flightPriceDetail : {
        response : null,
        loading  : false,
        error : null
    },
    goflyFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    goflyRoundFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    eflyFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    eflyRoundFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    satkarFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    satkarRoundFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    riyaFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
    riyaRoundFlightFareRuleDetails : {
        response : null,
        loading  : false
    },
};


export function flightSearchDetailsReducer(state = initialState, action) {
    switch (action.type) {
        //================================ For Flight Search API ===================================
        case FLIGHT_SEARCH_START:
            let data = JSON.parse(sessionStorage.getItem('flightSearchQuery'))
            const numberOfIndexes = data && data.segments && data.segments.length;
            // Create an array to hold the initial filters for each index
            const initialFiltersArray = Array.from({ length: numberOfIndexes }, () => ({
                stops: [],
                departTime: [],
                fareType: [],
                airlines: [],
                layover: [],
                segmentStops: [0],
                flight_number: null,
            }));
            return {
                ...state,
                loading: true,
                searchResult: null,
                tboSearched :false,
                kafilaSearched :false,
                tripJackSearched :false,
                airIQSearched : false,
                goflySearched :false,
                eflySearched:false,
                satkarSearched:false,
                riyaSearched: false,
                advanceSearchFilter: initialFiltersArray,
                flightPriceDetail : {
                    response : null,
                    loading  : false,
                    error : null
                }
            };
        case FLIGHT_SEARCH_KAFILA_START: 
        return {
            ...state,
            loading: true,
            kafilaSearched :false
        } 
        case FLIGHT_SEARCH_TRIPJACK_START: 
        return {
            ...state,
            loading: true,
            tripJackSearched :false
        }   
        case FLIGHT_SEARCH_AIRIQ_START:
        return {
            ...state,
            loading: true,
            airIQSearched :false
        } 
        case FLIGHT_SEARCH_GOFLY_START:
        return {
            ...state,
            loading: true,
            goflySearched :false
        } 
        case FLIGHT_SEARCH_EFLY_START:
            return {
                ...state,
                loading: true,
                eflySearched :false
        }
        case FLIGHT_SEARCH_SATKAR_START:
        return {
            ...state,
            loading: true,
            satkarSearched :false
        }
        case FLIGHT_SEARCH_RIYA_START:
        return {
            ...state,
            loading: true,
            riyaSearched :false
        } 
        case FLIGHT_SEARCH_OK:
            let searchDetailsStateTbo = Object.assign({}, state);
            if ((searchDetailsStateTbo.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateTbo.kafilaSearched) || (['1', '2', '3'].includes(action.searchFilters.journey_type) && searchDetailsStateTbo.tripJackSearched) || (['1', '2'].includes(action.searchFilters.journey_type) && action.searchFilters.pft === 'REGULAR' && searchDetailsStateTbo.airIQSearched) || (['1', '2'].includes(action.searchFilters.journey_type) && action.searchFilters.pft === 'REGULAR' && searchDetailsStateTbo.goflySearched) || (['1', '2'].includes(action.searchFilters.journey_type) && action.searchFilters.pft === 'REGULAR' && searchDetailsStateTbo.eflySearched) || (['1', '2'].includes(action.searchFilters.journey_type) && action.searchFilters.pft === 'REGULAR' && searchDetailsStateTbo.satkarSearched) || (['1', '2'].includes(action.searchFilters.journey_type) && action.searchFilters.pft === 'REGULAR' && searchDetailsStateTbo.riyaSearched) )
            {
          
                if(action.output && action.output.trace_id)
                {
                    searchDetailsStateTbo.searchResult.trace_id = action.output.trace_id;
                }
                let mergedResults = [];
                if(action.output && searchDetailsStateTbo.searchResult)
                {
                           
                    mergedResults = getSearchDetailsFormattedResults(searchDetailsStateTbo.searchResult.results, action.output.results);
                }
                if(mergedResults.length > 0)
                {
                    searchDetailsStateTbo.searchResult.results = mergedResults;
                }
                else if(action.output){
                    searchDetailsStateTbo.searchResult = action.output;
                }
                
                if(!['1', '2','3'].includes(action.searchFilters.journey_type))
                {
                    searchDetailsStateTbo.tripJackSearched = true;
                }
                if(!['1','2'].includes(action.searchFilters.journey_type) || action.searchFilters.pft !== 'REGULAR')
                {
                    searchDetailsStateTbo.airIQSearched = true;
                }
                if(!['1', '2','5'].includes(action.searchFilters.journey_type) || searchDetailsStateTbo.is_kafila_user === 'N')
                {
                    searchDetailsStateTbo.kafilaSearched = true;
                }
                if(!['1','2'].includes(action.searchFilters.journey_type) || action.searchFilters.pft !== 'REGULAR')
                {
                    searchDetailsStateTbo.goflySearched = true;
                    searchDetailsStateTbo.satkarSearched = true;
                    searchDetailsStateTbo.riyaSearched = true;
                }
                
                if (searchDetailsStateTbo.kafilaSearched && searchDetailsStateTbo.tripJackSearched && searchDetailsStateTbo.airIQSearched && searchDetailsStateTbo.goflySearched && searchDetailsStateTbo.eflySearched && searchDetailsStateTbo.satkarSearched && searchDetailsStateTbo.riyaSearched)
                {
                  
                   
                    searchDetailsStateTbo.loading = false;
                } 
                else
                {
                  
                    searchDetailsStateTbo.loading = true;
                }

                if(action.searchFilters.flightCabinClassName === "Premium Economy" || action.searchFilters.flightCabinClassName === "First" || action.searchFilters.flightCabinClassName === "Business"){
                    if(action.searchFilters.is_domestic === 'false'){
                        if(searchDetailsStateTbo.tripJackSearched && searchDetailsStateTbo.riyaSearched){
                            searchDetailsStateTbo.loading = false;
                        }
                    }else{
                        if(searchDetailsStateTbo.kafilaSearched && searchDetailsStateTbo.tripJackSearched && searchDetailsStateTbo.riyaSearched){
                            searchDetailsStateTbo.loading = false;
                        }
                    }
                    
                }
                // =======================lcc and gds return===============================================================
                if(action.searchFilters.roundTripType === "GDS" || action.searchFilters.roundTripType === "LCC"){ 
                  
                        searchDetailsStateTbo.loading = false;
                   
                }
              
                // =================================== special cabin class end =================================================
                if(searchDetailsStateTbo.tripJackSearched && searchDetailsStateTbo.kafilaSearched && searchDetailsStateTbo.riyaSearched  ){
                   
                    if (action.searchFilters.pft === 'STUDENT'  || action.searchFilters.pft === 'SENIOR_CITIZEN') {
                     
                        searchDetailsStateTbo.loading = false;
                    }
                }


                //  ********************************** round trip international *******************************************************
                if (action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                    // xxxxxxxxxxxxxxxxxxxxxxxxxxxx special cabin class round trip international xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                  
                    if((action.searchFilters.flightCabinClassName === "Premium Economy" || action.searchFilters.flightCabinClassName === "Business" || action.searchFilters.flightCabinClassName === "First")){
                        
                        if(searchDetailsStateTbo.tripJackSearched){
                          
                            searchDetailsStateTbo.loading = false;
                         }
                         
                        

                    // xxxxxxxxxxxxxxxxxxxxxxxxxxxx special cabin class round trip international end xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    }else{
                        if(searchDetailsStateTbo.tripJackSearched && searchDetailsStateTbo.riyaSearched && searchDetailsStateTbo.goflySearched && searchDetailsStateTbo.satkarSearched){
                            
                            searchDetailsStateTbo.loading = false;
                         }
                    }
                    if(searchDetailsStateTbo.tripJackSearched && searchDetailsStateTbo.riyaSearched ){
                       
                        if (action.searchFilters.pft === 'STUDENT'  || action.searchFilters.pft === 'SENIOR_CITIZEN') {
                          
                            searchDetailsStateTbo.loading = false;
                        }
                    }
                    // =========================================== lcc and gds round trip international ==================================================
                    if((action.searchFilters.roundTripType === "GDS" || action.searchFilters.roundTripType === "LCC")){
                        searchDetailsStateTbo.loading = false;
                    }
                   
                }
                if (action.searchFilters.is_domestic === 'false' && action.searchFilters.journey_url === 'multi-city') {
                    if (searchDetailsStateTbo.tripJackSearched === true) {
                            searchDetailsStateTbo.loading = false;
                    }
                }
                
              
                // ***********************************round trip intenational end *****************************************************
                searchDetailsStateTbo.tboSearched = true;
               
                return searchDetailsStateTbo;
            }
            else
            {
                
                let load = true;

                if(action.searchFilters.flightCabinClassName === "Premium Economy" || action.searchFilters.flightCabinClassName === "First" || action.searchFilters.flightCabinClassName === "Business"){
                    if(action.searchFilters.is_domestic === 'false'){
                        if(searchDetailsStateTbo.tripJackSearched && searchDetailsStateTbo.riyaSearched){
                            load = false;
                        }
                    }else{
                        if(searchDetailsStateTbo.kafilaSearched && searchDetailsStateTbo.tripJackSearched && searchDetailsStateTbo.riyaSearched){
                            load = false;
                        }
                    }
                    
                }
                if (searchDetailsStateTbo.tripJackSearched && action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                 
                    if(action.searchFilters.flightCabinClassName === "Premium Economy" || action.searchFilters.flightCabinClassName === "Business" || action.searchFilters.flightCabinClassName === "First"){
                    
                        load=false;
                    }
                }
                if (action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                   
                    if((action.searchFilters.roundTripType === "GDS" || action.searchFilters.roundTripType === "LCC" || action.searchFilters.roundTripType ==='normalReturn')){
                       
                        if (action.searchFilters.pft === 'STUDENT' || action.searchFilters.pft === 'SENIOR_CITIZEN') {
                            
                            if(searchDetailsStateTbo.tripJackSearched && searchDetailsStateTbo.riyaSearched){
                             
                                    load = false;
                            }
                            if(searchDetailsStateTbo.riyaSearched){
                                
                                    load = false;
                            }
                        }else{
                            if(action.searchFilters.roundTripType === "GDS" || action.searchFilters.roundTripType === "LCC"){
                               
                                if(searchDetailsStateTbo.riyaSearched){
                                   
                                    load = false;
                                }
                            }else{
                              
                                if(searchDetailsStateTbo.tripJackSearched && searchDetailsStateTbo.riyaSearched && searchDetailsStateTbo.satkarSearched && searchDetailsStateTbo.goflySearched ){
                                 
                                    load = false;
                                }
                            }
                            
                        }
                        
                    }
                    
                }
                if (searchDetailsStateTbo.tripJackSearched && searchDetailsStateTbo.goflySearched && action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
             
                    load=false;
                }
                if (searchDetailsStateTbo.tripJackSearched && action.searchFilters.is_domestic === 'false' && action.searchFilters.journey_url === "multi-city" && action.searchFilters.journey_type_name === 'Multi City' && action.searchFilters.journey_type === '3') {
             
                    load=false;
                }
                    
				if(!['1','2','5','3'].includes(action.searchFilters.journey_type))
				{
                    
					load = false;
				}
                if(action.searchFilters.is_domestic === 'true' && action.searchFilters.journey_url === "multi-city" ){
                    
                    load = false;
                }
              
                if(searchDetailsStateTbo.tripJackSearched === true && searchDetailsStateTbo.kafilaSearched === true && searchDetailsStateTbo.riyaSearched === true){
                  
                    if(action.searchFilters.pft === 'STUDENT' ||  action.searchFilters.pft === 'SENIOR_CITIZEN'){
                   
                        load = false; 
                    }
                }
                  
                if( searchDetailsStateTbo.riyaSearched && action.searchFilters.is_domestic === 'true' &&  (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')){
                
                    if(action.searchFilters.roundTripType === "GDS" || action.searchFilters.roundTripType === "LCC" ){
                       
                        load = false
                    }
                }
              
                return {
                    ...state,
                    loading: load,
                    searchResult: action.output ? action.output : {},
                    tboSearched:true,
                };
            }

        case FLIGHT_SEARCH_KAFILA_OK:
            let searchDetailsStateKafila = Object.assign({}, state);
            if(searchDetailsStateKafila.tboSearched || (['1', '2'].includes(action.searchFilters.journey_type) && searchDetailsStateKafila.tripJackSearched) || (['1','2'].includes(action.searchFilters.journey_type) && action.searchFilters.pft === 'REGULAR' && searchDetailsStateKafila.airIQSearched)|| (['1','2'].includes(action.searchFilters.journey_type) && action.searchFilters.pft === 'REGULAR' && searchDetailsStateKafila.goflySearched)|| (['1','2'].includes(action.searchFilters.journey_type) && action.searchFilters.pft === 'REGULAR' && searchDetailsStateKafila.eflySearched)|| (['1','2'].includes(action.searchFilters.journey_type) && action.searchFilters.pft === 'REGULAR' && searchDetailsStateKafila.eflySearched)|| (['1','2'].includes(action.searchFilters.journey_type) && action.searchFilters.pft === 'REGULAR' && searchDetailsStateKafila.satkarSearched) || (['1','2'].includes(action.searchFilters.journey_type) && action.searchFilters.pft === 'REGULAR' && searchDetailsStateKafila.riyaSearched))
            {
           
                if(action.output && action.output.deal)
                {
                    searchDetailsStateKafila.searchResult.deal = action.output.deal;
                }
                if(action && action.output && action.output.Param)
                {
                    searchDetailsStateKafila.searchResult.param = action.output.Param;
                }
                if(action.output && action.output.param_return)
                {
                    searchDetailsStateKafila.searchResult.param_return = action.output.param_return;
                }
                if(action.output && action.output.param_rt)
                {
                    searchDetailsStateKafila.searchResult.param_rt = action.output.param_rt;
                }
                let mergedResults = [];
                if(action.output && searchDetailsStateKafila.searchResult)
                {
                    mergedResults = getSearchDetailsFormattedResults(searchDetailsStateKafila.searchResult.results, action.output.results);
                }
                if(mergedResults.length > 0)
                {
                    searchDetailsStateKafila.searchResult.results = mergedResults;
                }
                else if(action.output){
                    searchDetailsStateKafila.searchResult = action.output;
                }
                if(!['1', '2'].includes(action.searchFilters.journey_type))
                {
                    searchDetailsStateKafila.tripJackSearched = true;
                }
               
                if(searchDetailsStateKafila.tboSearched && searchDetailsStateKafila.tripJackSearched && searchDetailsStateKafila.airIQSearched && searchDetailsStateKafila.goflySearched && searchDetailsStateKafila.eflySearched && searchDetailsStateKafila.satkarSearched && searchDetailsStateKafila.riyaSearched)
                  
                {
                   
                    searchDetailsStateKafila.loading = false;
                }
                else
                {
                  
                    searchDetailsStateKafila.loading = true;
                }
                if(searchDetailsStateKafila.tripJackSearched && searchDetailsStateKafila.tboSearched && searchDetailsStateKafila.riyaSearched ){
                   
                    if (action.searchFilters.pft === 'STUDENT'  || action.searchFilters.pft === 'SENIOR_CITIZEN') {
                      
                        searchDetailsStateKafila.loading = false;
                    }
                }
                searchDetailsStateKafila.kafilaSearched = true;
             
               
                return searchDetailsStateKafila;
            }
            else
            {
               
                let load = true;
                    if(searchDetailsStateKafila.tripJackSearched === true && searchDetailsStateKafila.tboSearched === true && searchDetailsStateKafila.riyaSearched === true){
                     
                        if(action.searchFilters.pft === 'STUDENT' ||  action.searchFilters.pft === 'SENIOR_CITIZEN'){
                          
                            load = false; 
                        }
                    }
                
                 
                return {
                    ...state,
                    loading: load,
                    searchResult: action.output ? action.output : {},
                    kafilaSearched:true,
                };
                
            }
            
        case FLIGHT_SEARCH_TRIPJACK_OK:
            let searchDetailsStateTripJack = Object.assign({}, state);
            if(((searchDetailsStateTripJack.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateTripJack.kafilaSearched) || (['1','2'].includes(action.searchFilters.journey_type) && searchDetailsStateTripJack.airIQSearched) || (['1','2'].includes(action.searchFilters.journey_type) && searchDetailsStateTripJack.goflySearched)|| (['1','2'].includes(action.searchFilters.journey_type) && searchDetailsStateTripJack.satkarSearched) ||(['1','2'].includes(action.searchFilters.journey_type) && searchDetailsStateTripJack.eflySearched)  || (['1','2'].includes(action.searchFilters.journey_type) && searchDetailsStateTripJack.riyaSearched) || searchDetailsStateTripJack.tboSearched) && action.searchFilters.pft === 'REGULAR')
            {
               
                let mergedResults = [];
                if(action.output && searchDetailsStateTripJack.searchResult)
                {
                    mergedResults = getSearchDetailsFormattedResults(searchDetailsStateTripJack.searchResult.results, action.output.results);
                }
                if(mergedResults.length > 0)
                {
                    searchDetailsStateTripJack.searchResult.results = mergedResults;
                }
                else if(action.output){
                    searchDetailsStateTripJack.searchResult = action.output;
                }
                
                
                if(!['1','2','5'].includes(action.searchFilters.journey_type) || searchDetailsStateTripJack.is_kafila_user === 'N')
                {
                    searchDetailsStateTripJack.kafilaSearched = true;
                }
              
                if(searchDetailsStateTripJack.tboSearched && searchDetailsStateTripJack.kafilaSearched && searchDetailsStateTripJack.airIQSearched && searchDetailsStateTripJack.goflySearched && searchDetailsStateTripJack.eflySearched && searchDetailsStateTripJack.satkarSearched && searchDetailsStateTripJack.riyaSearched)
                    
                {
                   
                    searchDetailsStateTripJack.loading = false;
                }
                else
                {
                    
                    searchDetailsStateTripJack.loading = true;
                }
               
                
                if(action.searchFilters.flightCabinClassName === "Premium Economy" || action.searchFilters.flightCabinClassName === "Business" || action.searchFilters.flightCabinClassName === "First"){
                    if(action.searchFilters.is_domestic === 'false'){
                        if(searchDetailsStateTripJack.tboSearched && searchDetailsStateTripJack.riyaSearched){
                  
                            searchDetailsStateTripJack.loading=false;
                        }
                    }else{
                        if(searchDetailsStateTripJack.tboSearched && searchDetailsStateTripJack.kafilaSearched && searchDetailsStateTripJack.riyaSearched){
                  
                            searchDetailsStateTripJack.loading=false;
                        }
                    }
                    
                }
                if (action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                    if(action.searchFilters.flightCabinClassName === "Premium Economy" || action.searchFilters.flightCabinClassName === "Business" || action.searchFilters.flightCabinClassName === "First"){
                        if(searchDetailsStateTripJack.tboSearched && searchDetailsStateTripJack.kafilaSearched && searchDetailsStateTripJack.riyaSearched){
                       
                            searchDetailsStateTripJack.loading=false;
                        }
                    }else{
                      
                        if(searchDetailsStateTripJack.tboSearched && searchDetailsStateTripJack.goflySearched && searchDetailsStateTripJack.riyaSearched && searchDetailsStateTripJack.satkarSearched){
                           
                            searchDetailsStateTripJack.loading=false;
                        }
                    }
                    if(searchDetailsStateTripJack.tboSearched === true){
                        if (action.searchFilters.pft === 'STUDENT' || action.searchFilters.pft === 'SENIOR_CITIZEN') {
                            searchDetailsStateTripJack.loading = false;
                        }
                    }
                }
                if (action.searchFilters.is_domestic === 'false' && action.searchFilters.journey_url === 'multi-city') {
                    if (searchDetailsStateTripJack.tboSearched === true) {
                            searchDetailsStateTripJack.loading = false;
                    }
                }
                if(searchDetailsStateTripJack.kafilaSearched && searchDetailsStateTripJack.tboSearched && searchDetailsStateTripJack.riyaSearched){
             
                    if (action.searchFilters.pft === 'STUDENT'  || action.searchFilters.pft === 'SENIOR_CITIZEN') {
                        
                        searchDetailsStateTripJack.loading = false;
                    }
                }
                searchDetailsStateTripJack.tripJackSearched = true;
               
                return searchDetailsStateTripJack;
            }
            else
            {
                
                let load = true;
                if (searchDetailsStateTripJack.tboSearched && searchDetailsStateTripJack.riyaSearched && action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                   
                    if(action.searchFilters.flightCabinClassName === "Premium Economy" || action.searchFilters.flightCabinClassName === "Business" || action.searchFilters.flightCabinClassName === "First"){
                       // load=false;
                      
                    }
                    if(searchDetailsStateTripJack.tboSearched === true && searchDetailsStateTripJack.riyaSearched === true){
                       
                        if (action.searchFilters.pft === 'STUDENT' || action.searchFilters.pft === 'SENIOR_CITIZEN') {
                          
                            load = false;
                        }
                        if (action.searchFilters.journey_type_name === 'Multi City' && action.searchFilters.journey_type === '3') {
                           
                            load = false;
                        }
                    }
                }
                if (searchDetailsStateTripJack.tboSearched && action.searchFilters.is_domestic === 'false' &&  action.searchFilters.journey_url === 'multi-city') {
                    
                    if(searchDetailsStateTripJack.tboSearched === true){
                     
                            load = false;
                    }
                }
                if (searchDetailsStateTripJack.tboSearched && searchDetailsStateTripJack.goflySearched && action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                  
                    load=false;
                }
                if(searchDetailsStateTripJack.tboSearched && searchDetailsStateTripJack.kafilaSearched && searchDetailsStateTripJack.riyaSearched && action.searchFilters.journey_url === "one-way"){
                  
                    if(action.searchFilters.pft === 'STUDENT' ||  action.searchFilters.pft === 'SENIOR_CITIZEN'){
                       
                        load = false; 
                    }
                }
                if(action.searchFilters.flightCabinClassName === "Premium Economy" || action.searchFilters.flightCabinClassName === "Business" || action.searchFilters.flightCabinClassName === "First"){
                    
                    if(action.searchFilters.pft === 'STUDENT' ||  action.searchFilters.pft === 'SENIOR_CITIZEN'){
                        if (action.searchFilters.is_domestic === 'false') {
                            if(searchDetailsStateTripJack.tboSearched &&  searchDetailsStateTripJack.riyaSearched){
                        
                                load=false;
                            }
                        }else{
                            if(searchDetailsStateTripJack.tboSearched && searchDetailsStateTripJack.kafilaSearched && searchDetailsStateTripJack.riyaSearched){
                        
                                load=false;
                            }
                        }
                        
                        
                    }
                   
                   
                 }

            
                return {
                    ...state,
                    loading : load,
                    searchResult: action.output ? action.output : {},
                    tripJackSearched:true,
                };
            }
            case FLIGHT_SEARCH_AIRIQ_OK:
                    let searchDetailsStateAirIQ = Object.assign({}, state);
                    if((searchDetailsStateAirIQ.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateAirIQ.kafilaSearched) || searchDetailsStateAirIQ.tboSearched || searchDetailsStateAirIQ.tripJackSearched || searchDetailsStateAirIQ.goflySearched||searchDetailsStateAirIQ.eflySearched||searchDetailsStateAirIQ.satkarSearched ||searchDetailsStateAirIQ.riyaSearched)
                    {
                        let mergedResults = [];
                        if(action.output && searchDetailsStateAirIQ.searchResult)
                        {
                             mergedResults = getSearchDetailsFormattedResults(searchDetailsStateAirIQ.searchResult.results, action.output.results);
                        }
                        if(mergedResults.length > 0)
                        {
                            searchDetailsStateAirIQ.searchResult.results = mergedResults;
                        }
                        else if(action.output){
                            searchDetailsStateAirIQ.searchResult = action.output;
                        }
                        if ((searchDetailsStateAirIQ.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateAirIQ.kafilaSearched && searchDetailsStateAirIQ.tboSearched && searchDetailsStateAirIQ.tripJackSearched && searchDetailsStateAirIQ.goflySearched && searchDetailsStateAirIQ.satkarSearched && searchDetailsStateAirIQ.eflySearched && searchDetailsStateAirIQ.riyaSearched) || (searchDetailsStateAirIQ.is_kafila_user === 'Y' && !['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateAirIQ.tboSearched) || (searchDetailsStateAirIQ.is_kafila_user === 'N' && searchDetailsStateAirIQ.tboSearched && searchDetailsStateAirIQ.tripJackSearched && searchDetailsStateAirIQ.goflySearched && searchDetailsStateAirIQ.satkarSearched && searchDetailsStateAirIQ.eflySearched && searchDetailsStateAirIQ.riyaSearched))
                        {
                            searchDetailsStateAirIQ.loading = false;
                        }
                        else
                        {
                            searchDetailsStateAirIQ.loading = true;
                        }
                       
                        searchDetailsStateAirIQ.airIQSearched = true;
                        return searchDetailsStateAirIQ;
                    }
                    else
                    {
                        let load=true
                       
                        return {
                            ...state,
                            loading : load,
                            searchResult: action.output ? action.output : {},
                            airIQSearched:true,
                        };
                    }    
                    
        case FLIGHT_SEARCH_GOFLY_OK:
            let searchDetailsStateGofly = Object.assign({}, state);
            if ((searchDetailsStateGofly.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateGofly.kafilaSearched) || searchDetailsStateGofly.tboSearched || searchDetailsStateGofly.tripJackSearched || searchDetailsStateGofly.airIQSearched|| searchDetailsStateGofly.eflySearched || searchDetailsStateGofly.satkarSearched || searchDetailsStateGofly.riyaSearched) {
              
                let mergedResults = [];
                if (action.output && searchDetailsStateGofly.searchResult) {
                    mergedResults = getSearchDetailsFormattedResults(searchDetailsStateGofly.searchResult.results, action.output.results);
                }
                if (mergedResults.length > 0) {
                    searchDetailsStateGofly.searchResult.results = mergedResults;
                }
                else if (action.output) {
                    searchDetailsStateGofly.searchResult = action.output;
                }
                if ((searchDetailsStateGofly.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateGofly.kafilaSearched && searchDetailsStateGofly.tboSearched && searchDetailsStateGofly.tripJackSearched && searchDetailsStateGofly.airIQSearched && searchDetailsStateGofly.eflySearched && searchDetailsStateGofly.satkarSearched && searchDetailsStateGofly.riyaSearched) || (searchDetailsStateGofly.is_kafila_user === 'Y' && !['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateGofly.tboSearched) || (searchDetailsStateGofly.is_kafila_user === 'N' && searchDetailsStateGofly.tboSearched && searchDetailsStateGofly.tripJackSearched && searchDetailsStateGofly.airIQSearched && searchDetailsStateGofly.eflySearched && searchDetailsStateGofly.satkarSearched && searchDetailsStateGofly.riyaSearched)) {
                    searchDetailsStateGofly.loading = false;
                }
                else {
                    searchDetailsStateGofly.loading = true;
                }
               
                if (action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                    if (searchDetailsStateGofly.tripJackSearched && searchDetailsStateGofly.tboSearched && searchDetailsStateGofly.riyaSearched && searchDetailsStateGofly.satkarSearched) {
                     
                        searchDetailsStateGofly.loading = false;
                    }
                }
                searchDetailsStateGofly.goflySearched = true;
                return searchDetailsStateGofly;
            }
            else {
                let load=true
                if (action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                    if (searchDetailsStateGofly.tripJackSearched && searchDetailsStateGofly.tboSearched) {
                     
                        load = false;
                    }
                   
                }
                return {
                    ...state,
                    loading: load,
                    searchResult: action.output ? action.output : {},
                    goflySearched: true,
                };
            }
        
        case FLIGHT_SEARCH_EFLY_OK:
            let searchDetailsStateefly = Object.assign({}, state);
            if ((searchDetailsStateefly.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateefly.kafilaSearched) || searchDetailsStateefly.tboSearched || searchDetailsStateefly.tripJackSearched || searchDetailsStateefly.airIQSearched || searchDetailsStateefly.goflySearched || searchDetailsStateefly.satkarSearched || searchDetailsStateefly.riyaSearched) {
                let mergedResults = [];
                if (action.output && searchDetailsStateefly.searchResult) {
                    mergedResults = getSearchDetailsFormattedResults(searchDetailsStateefly.searchResult.results, action.output.results);
                }
                if (mergedResults.length > 0) {
                    searchDetailsStateefly.searchResult.results = mergedResults;
                }
                else if (action.output) {
                    searchDetailsStateefly.searchResult = action.output;
                }
                if ((searchDetailsStateefly.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateefly.kafilaSearched && searchDetailsStateefly.tboSearched && searchDetailsStateefly.tripJackSearched && searchDetailsStateefly.airIQSearched && searchDetailsStateefly.goflySearched && searchDetailsStateefly.satkarSearched && searchDetailsStateefly.riyaSearched) || (searchDetailsStateefly.is_kafila_user === 'Y' && !['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateefly.tboSearched) || (searchDetailsStateefly.is_kafila_user === 'N' && searchDetailsStateefly.tboSearched && searchDetailsStateefly.tripJackSearched && searchDetailsStateefly.airIQSearched && searchDetailsStateefly.goflySearched && searchDetailsStateefly.satkarSearched && searchDetailsStateefly.riyaSearched)) {
                    searchDetailsStateefly.loading = false;
                }
                else {
                    searchDetailsStateefly.loading = true;
                }
              
                searchDetailsStateefly.eflySearched = true;
                return searchDetailsStateefly;
            }
            else {
                let load=true;
               
                return {
                    ...state,
                    loading: load,
                    searchResult: action.output ? action.output : {},
                    eflySearched: true,
                };
            }  

        case FLIGHT_SEARCH_SATKAR_OK:
            let searchDetailsStateSatkar = Object.assign({}, state);
            if ((searchDetailsStateSatkar.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateSatkar.kafilaSearched) || searchDetailsStateSatkar.tboSearched || searchDetailsStateSatkar.tripJackSearched || searchDetailsStateSatkar.airIQSearched || searchDetailsStateSatkar.eflySearched  || searchDetailsStateSatkar.goflySearched || searchDetailsStateSatkar.riyaSearched) {
              
                let mergedResults = [];
                if (action.output && searchDetailsStateSatkar.searchResult) {
                    mergedResults = getSearchDetailsFormattedResults(searchDetailsStateSatkar.searchResult.results, action.output.results);
                }
                if (mergedResults.length > 0) {
                    searchDetailsStateSatkar.searchResult.results = mergedResults;
                }
                else if (action.output) {
                    searchDetailsStateSatkar.searchResult = action.output;
                }
                if ((searchDetailsStateSatkar && searchDetailsStateSatkar.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateSatkar.kafilaSearched && searchDetailsStateSatkar.tboSearched && searchDetailsStateSatkar.tripJackSearched && searchDetailsStateSatkar.airIQSearched && searchDetailsStateSatkar.eflySearched && searchDetailsStateSatkar.riyaSearched) || (searchDetailsStateSatkar.is_kafila_user === 'Y' && !['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateSatkar.tboSearched) || (searchDetailsStateSatkar.is_kafila_user === 'N' && searchDetailsStateSatkar.tboSearched && searchDetailsStateSatkar.tripJackSearched && searchDetailsStateSatkar.airIQSearched && searchDetailsStateSatkar.eflySearched && searchDetailsStateSatkar.riyaSearched)) {
                  
                    searchDetailsStateSatkar.loading = false;
                }
                else {
                    
                    searchDetailsStateSatkar.loading = true;
                }
               
                if (action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                    if (searchDetailsStateSatkar.tripJackSearched && searchDetailsStateSatkar.tboSearched && searchDetailsStateSatkar.riyaSearched&& searchDetailsStateSatkar.goflySearched) {
                      
                        searchDetailsStateSatkar.loading = false;
                    }
                }
                searchDetailsStateSatkar.satkarSearched = true;
                return searchDetailsStateSatkar;
            }
            else {
                let load = true
                if (action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                    if (searchDetailsStateSatkar.tripJackSearched && searchDetailsStateSatkar.tboSearched) {
                       
                        load = false;
                    }
                  
                }
                return {
                    ...state,
                    loading: load,
                    searchResult: action.output ? action.output : {},
                    satkarSearched: true,
                };
            }  
        case FLIGHT_SEARCH_SATKAR_ERR:
            /* if ITINERARIES send falied */
            return {
                ...state,
                loading: true,
                satkarSearched: false
            }

        case FLIGHT_SEARCH_RIYA_OK:
            let searchDetailsStateRiya = Object.assign({}, state);
            if ((searchDetailsStateRiya.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateRiya.kafilaSearched) || searchDetailsStateRiya.tboSearched || searchDetailsStateRiya.tripJackSearched || searchDetailsStateRiya.airIQSearched || searchDetailsStateRiya.eflySearched || searchDetailsStateRiya.goflySearched || searchDetailsStateRiya.satkarSearched) {
         
                let mergedResults = [];
                if (action.output && searchDetailsStateRiya.searchResult) {
                    mergedResults = getSearchDetailsFormattedResults(searchDetailsStateRiya.searchResult.results, action.output.results);
                }
                if (mergedResults.length > 0) {
                    searchDetailsStateRiya.searchResult.results = mergedResults;
                }
                else if (action.output) {
                    searchDetailsStateRiya.searchResult = action.output;
                }
                if ((searchDetailsStateRiya.is_kafila_user === 'Y' && ['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateRiya.kafilaSearched && searchDetailsStateRiya.tboSearched && searchDetailsStateRiya.tripJackSearched && searchDetailsStateRiya.airIQSearched && searchDetailsStateRiya.eflySearched && searchDetailsStateRiya.goflySearched && searchDetailsStateRiya.satkarSearched) || (searchDetailsStateRiya.is_kafila_user === 'Y' && !['1', '2', '5'].includes(action.searchFilters.journey_type) && searchDetailsStateRiya.tboSearched) || (searchDetailsStateRiya.is_kafila_user === 'N' && searchDetailsStateRiya.tboSearched && searchDetailsStateRiya.tripJackSearched && searchDetailsStateRiya.airIQSearched && searchDetailsStateRiya.eflySearched && searchDetailsStateRiya.goflySearched && searchDetailsStateRiya.satkarSearched)) {
                   
                    searchDetailsStateRiya.loading = false;
                }
                else {
                  
                    searchDetailsStateRiya.loading = true;
                }
                if ((action.searchFilters.flightCabinClassName === "Premium Economy" || action.searchFilters.flightCabinClassName === "First" || action.searchFilters.flightCabinClassName === "Business")) {
                    if (action.searchFilters.is_domestic === 'false') {
                        if (searchDetailsStateRiya.tboSearched && searchDetailsStateRiya.tripJackSearched) {
                            searchDetailsStateRiya.loading = false;
                        }
                    } else {
                        if (searchDetailsStateRiya.tboSearched && searchDetailsStateRiya.kafilaSearched && searchDetailsStateRiya.tripJackSearched) {
                            searchDetailsStateRiya.loading = false;
                        }
                    }
                }
                if (action.searchFilters.is_domestic === 'false' && (action.searchFilters.roundTripType === "GDS" || action.searchFilters.roundTripType === "LCC" || action.searchFilters.roundTripType ==='normalReturn') && 
                    (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                        
                    if (action.searchFilters.pft === 'STUDENT' || action.searchFilters.pft === 'SENIOR_CITIZEN') {
                     
                        if (searchDetailsStateRiya.tripJackSearched && searchDetailsStateRiya.tboSearched) {
                           
                            searchDetailsStateRiya.loading = false;
                        }
                        if (searchDetailsStateRiya.tboSearched) {

                            searchDetailsStateRiya.loading = false;
                        }
                    } else {
                     
                        if(action.searchFilters.roundTripType === "GDS" || action.searchFilters.roundTripType === "LCC"){
                            if ( searchDetailsStateRiya.tboSearched ) {
                                
                                searchDetailsStateRiya.loading = false;
                            }
                        }else{
                            if (searchDetailsStateRiya.tripJackSearched && searchDetailsStateRiya.tboSearched && searchDetailsStateRiya.satkarSearched && searchDetailsStateRiya.goflySearched) {
                                
                                searchDetailsStateRiya.loading = false;
                            }
                        }
                        
                        // if (searchDetailsStateRiya.tboSearched) {
                           
                        //     searchDetailsStateRiya.loading = false;
                        // }
                    }
                }
                if(searchDetailsStateRiya.tripJackSearched === true && searchDetailsStateRiya.kafilaSearched === true && searchDetailsStateRiya.tboSearched === true){
                
                    if (action.searchFilters.pft === 'STUDENT' || action.searchFilters.pft === 'SENIOR_CITIZEN') {
                       
                        searchDetailsStateRiya.loading = false;
                    }
                }
                if(action.searchFilters.is_domestic !== 'false' && (action.searchFilters.roundTripType === "GDS" || action.searchFilters.roundTripType === "LCC")){
                   
                    searchDetailsStateRiya.loading = false;
                }
                searchDetailsStateRiya.riyaSearched = true;
                
                return searchDetailsStateRiya;
            }
            else {
                
                let load = true
                if (action.searchFilters.is_domestic === 'false' && (action.searchFilters.journey_url === "round-trip" || action.searchFilters.journey_url === "round-trip-complete" || action.searchFilters.journey_url === "special-return" || action.searchFilters.journey_url === 'lcc-special-return')) {
                    
                    if(action.searchFilters.roundTripType === "GDS" || action.searchFilters.roundTripType === "LCC"){
                        if (searchDetailsStateRiya.tboSearched) {
                           
                            load = false;
                        }
                    }else{
                        if (searchDetailsStateRiya.tripJackSearched && searchDetailsStateRiya.tboSearched) {
                          
                            load = false;
                        }
                    }
                    
                }
                if(searchDetailsStateRiya.tboSearched && searchDetailsStateRiya.kafilaSearched && searchDetailsStateRiya.tripJackSearched && action.searchFilters.journey_url === "one-way"){
                   
                    if(action.searchFilters.pft === 'STUDENT' ||  action.searchFilters.pft === 'SENIOR_CITIZEN'){
                       
                        load = false; 
                    }
                }
              
                return {
                    ...state,
                    loading: load,
                    searchResult: action.output ? action.output : {},
                    riyaSearched: true,
                };
            }     


         //================================ For ITINERARIES mails ===================================
         case ITINERARIES_EMAIL_START :
         return {
             ...state, 
             ItinerariesMail : {
                response : null,
                 loading  : true
             }
         }
        case ITINERARIES_EMAIL_OK :
            /* if ITINERARIES send successfully */
            return {
                ...state, 
                ItinerariesMail : {
                    response    : action.response,
                    loading     : false
                }
            };
        case ITINERARIES_EMAIL_ERR :
            /* if ITINERARIES send falied */
            return {
                ...state, 
                ItinerariesMail : {
                    response : null,
                    loading : false
                }
            }
        
        //================================ For flight fare rules details ===================================
        case FLIGHT_FARE_RULE_DETAILS_START :
            return {
                ...state, 
                flightFareRuleDetails : {
                    response : null,
                    loading  : true
                }
         }
        case FLIGHT_FARE_RULE_DETAILS_OK :
            return {
                ...state, 
                flightFareRuleDetails : {
                    response    : action.output,
                    loading     : false
                }
            };
        case FLIGHT_FARE_RULE_DETAILS_ERR :
            return {
                ...state, 
                flightFareRuleDetails : {
                    response : null,
                    loading : false
                }
            }
            case ROUND_FLIGHT_FARE_RULE_DETAILS_START :
                return {
                    ...state, 
                    roundFlightFareRuleDetails : {
                        response : null,
                        loading  : true
                    }
             }
            case ROUND_FLIGHT_FARE_RULE_DETAILS_OK :
                return {
                    ...state, 
                    roundFlightFareRuleDetails : {
                        response    : action.output,
                        loading     : false
                    }
                };
            case ROUND_FLIGHT_FARE_RULE_DETAILS_ERR :
                return {
                    ...state, 
                    roundFlightFareRuleDetails : {
                        response : null,
                        loading : false
                    }
                }    
                case SET_FLIGHT_FARE_RULE_DETAILS :
                    return {
                        ...state, 
                        flightFareRuleDetails : {
                            response : action.response,
                            loading : false
                        }
                    } 
        //================================ For Trip Jack flight fare rules details ===================================
        case TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_START :
            return {
                ...state, 
                tripJackFlightFareRuleDetails : {
                    response : null,
                    loading  : true
                }
         }
        case TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_OK :
            return {
                ...state, 
                tripJackFlightFareRuleDetails : {
                    response    : action.output,
                    loading     : false
                }
            };
        case TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_ERR :
            return {
                ...state, 
                tripJackFlightFareRuleDetails : {
                    response : null,
                    loading : false
                }
            } 
        case TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_START :
            return {
                ...state, 
                tripJackRoundFlightFareRuleDetails : {
                    response : null,
                    loading  : true
                }
            }
        case TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_OK :
            return {
                ...state, 
                tripJackRoundFlightFareRuleDetails : {
                    response    : action.output,
                    loading     : false
                }
            };
        case TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR :
            return {
                ...state, 
                tripJackRoundFlightFareRuleDetails : {
                    response : null,
                    loading : false
                }
            }   
        case SET_TRIP_JACK_FLIGHT_FARE_RULE_DETAILS :
            return {
                ...state, 
                tripJackFlightFareRuleDetails : {
                    response : action.response,
                    loading : false
                }
            } 
        //================================ For Kafila flight fare rules details ===================================
        case KAFILA_FLIGHT_FARE_RULE_DETAILS_START :
            return {
                ...state, 
                kafilaFlightFareRuleDetails : {
                    response : null,
                    loading  : true
                }
         }
        case KAFILA_FLIGHT_FARE_RULE_DETAILS_OK :
            return {
                ...state, 
                kafilaFlightFareRuleDetails : {
                    response    : action.output,
                    loading     : false
                }
            };
        case KAFILA_FLIGHT_FARE_RULE_DETAILS_ERR :
            return {
                ...state, 
                kafilaFlightFareRuleDetails : {
                    response : null,
                    loading : false
                }
            } 
        case KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_START :
            return {
                ...state, 
                kafilaRoundFlightFareRuleDetails : {
                    response : null,
                    loading  : true
                }
            }
        case KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_OK :
            return {
                ...state, 
                kafilaRoundFlightFareRuleDetails : {
                    response    : action.output,
                    loading     : false
                }
            };
        case KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR :
            return {
                ...state, 
                kafilaRoundFlightFareRuleDetails : {
                    response : null,
                    loading : false
                }
            }   
        case SET_KAFILA_FLIGHT_FARE_RULE_DETAILS :
            return {
                ...state, 
                kafilaFlightFareRuleDetails : {
                    response : action.response,
                    loading : false
                }
            }
        //================================ For AirIQ flight fare rules details ===================================
        case AIRIQ_FLIGHT_FARE_RULE_DETAILS_START :
            return {
                ...state, 
                airIQFlightFareRuleDetails : {
                    response : null,
                    loading  : true
                }
         }
        case AIRIQ_FLIGHT_FARE_RULE_DETAILS_OK :
            return {
                ...state, 
                airIQFlightFareRuleDetails : {
                    response    : action.output,
                    loading     : false
                }
            };
        case AIRIQ_FLIGHT_FARE_RULE_DETAILS_ERR :
            return {
                ...state, 
                airIQFlightFareRuleDetails : {
                    response : null,
                    loading : false
                }
            } 
        case AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_START :
            return {
                ...state, 
               airIQRoundFlightFareRuleDetails : {
                    response : null,
                    loading  : true
                }
            }
        case AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_OK :
            return {
                ...state, 
                airIQRoundFlightFareRuleDetails : {
                    response    : action.output,
                    loading     : false
                }
            };
        case AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR :
            return {
                ...state, 
                airIQRoundFlightFareRuleDetails : {
                    response : null,
                    loading : false
                }
            }   
        case SET_AIRIQ_FLIGHT_FARE_RULE_DETAILS :
            return {
                ...state, 
                airIQFlightFareRuleDetails : {
                    response : action.response,
                    loading : false
                }
            }   
            
        //================================ For Gofly flight fare rules details ===================================
        case GOFLY_FLIGHT_FARE_RULE_DETAILS_START :
            return {
                ...state, 
                goflyFlightFareRuleDetails : {
                    response : null,
                    loading  : true
                }
         }
        case GOFLY_FLIGHT_FARE_RULE_DETAILS_OK :
            return {
                ...state, 
                goflyFlightFareRuleDetails : {
                    response    : action.output,
                    loading     : false
                }
            };
        case GOFLY_FLIGHT_FARE_RULE_DETAILS_ERR :
            return {
                ...state, 
                goflyFlightFareRuleDetails : {
                    response : null,
                    loading : false
                }
            } 
        case GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_START :
            return {
                ...state, 
                goflyRoundFlightFareRuleDetails : {
                    response : null,
                    loading  : true
                }
            }
        case GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_OK :
            return {
                ...state, 
                goflyRoundFlightFareRuleDetails : {
                    response    : action.output,
                    loading     : false
                }
            };
        case GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR :
            return {
                ...state, 
                goflyRoundFlightFareRuleDetails : {
                    response : null,
                    loading : false
                }
            }   
        case SET_GOFLY_FLIGHT_FARE_RULE_DETAILS :
            return {
                ...state, 
                goflyFlightFareRuleDetails : {
                    response : action.response,
                    loading : false
                }
            } 
        //================================ For flight price details ===================================

        //================================ For Efly flight fare rules details ===================================
        case EFLY_FLIGHT_FARE_RULE_DETAILS_START:
            return {
                ...state,
                eflyFlightFareRuleDetails: {
                    response: null,
                    loading: true
                }
            }
        case EFLY_FLIGHT_FARE_RULE_DETAILS_OK:
            return {
                ...state,
                eflyFlightFareRuleDetails: {
                    response: action.output,
                    loading: false
                }
            };
        case EFLY_FLIGHT_FARE_RULE_DETAILS_ERR:
            return {
                ...state,
                eflyFlightFareRuleDetails: {
                    response: null,
                    loading: false
                }
            }
        case EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_START:
            return {
                ...state,
                eflyRoundFlightFareRuleDetails: {
                    response: null,
                    loading: true
                }
            }
        case EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_OK:
            return {
                ...state,
                eflyRoundFlightFareRuleDetails: {
                    response: action.output,
                    loading: false
                }
            };
        case EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR:
            return {
                ...state,
                eflyRoundFlightFareRuleDetails: {
                    response: null,
                    loading: false
                }
            }
        case SET_EFLY_FLIGHT_FARE_RULE_DETAILS:
            return {
                ...state,
                eflyFlightFareRuleDetails: {
                    response: action.response,
                    loading: false
                }
            }
                    //================================ For flight price details ===================================
        //================================ For satkar flight fare rules details ===================================
        case SATKAR_FLIGHT_FARE_RULE_DETAILS_START:
            return {
                ...state,
                satkarFlightFareRuleDetails: {
                    response: null,
                    loading: true
                }
            }
        case SATKAR_FLIGHT_FARE_RULE_DETAILS_OK:
            return {
                ...state,
                satkarFlightFareRuleDetails: {
                    response: action.output,
                    loading: false
                }
            };
        case SATKAR_FLIGHT_FARE_RULE_DETAILS_ERR:
            return {
                ...state,
                satkarFlightFareRuleDetails: {
                    response: null,
                    loading: false
                }
            }
        case SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_START:
            return {
                ...state,
                satkarRoundFlightFareRuleDetails: {
                    response: null,
                    loading: true
                }
            }
        case SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_OK:
            return {
                ...state,
                satkarRoundFlightFareRuleDetails: {
                    response: action.output,
                    loading: false
                }
            };
        case SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR:
            return {
                ...state,
                satkarRoundFlightFareRuleDetails: {
                    response: null,
                    loading: false
                }
            }
        case SET_SATKAR_FLIGHT_FARE_RULE_DETAILS:
            return {
                ...state,
                satkarFlightFareRuleDetails: {
                    response: action.response,
                    loading: false
                }
            }
            
        //================================ For Riya flight fare rules details ===================================
        case RIYA_FLIGHT_FARE_RULE_DETAILS_START:
            return {
                ...state,
                riyaFlightFareRuleDetails: {
                    response: null,
                    loading: true
                }
            }
        case RIYA_FLIGHT_FARE_RULE_DETAILS_OK:
            return {
                ...state,
                riyaFlightFareRuleDetails: {
                    response: action.output,
                    loading: false
                }
            };
        case RIYA_FLIGHT_FARE_RULE_DETAILS_ERR:
            return {
                ...state,
                riyaFlightFareRuleDetails: {
                    response: null,
                    loading: false
                }
            }
        case RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_START:
            return {
                ...state,
                riyaRoundFlightFareRuleDetails: {
                    response: null,
                    loading: true
                }
            }
        case RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_OK:
            return {
                ...state,
                riyaRoundFlightFareRuleDetails: {
                    response: action.output,
                    loading: false
                }
            };
        case RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR:
            return {
                ...state,
                riyaRoundFlightFareRuleDetails: {
                    response: null,
                    loading: false
                }
            }
        case SET_RIYA_FLIGHT_FARE_RULE_DETAILS:
            return {
                ...state,
                riyaFlightFareRuleDetails: {
                    response: action.response,
                    loading: false
                }
            } 
        //================================ For flight price details ===================================
        case FLIGHT_PRICE_DETAILS_START :
            return {
                ...state, 
                flightPriceDetail : {
                    response : null,
                    loading  : true,
                    error : null
                }
         }
        case FLIGHT_PRICE_DETAILS_OK :
            return {
                ...state, 
                flightPriceDetail : {
                    response    : action.output,
                    loading     : false,
                    error : null
                }
            };
        case FLIGHT_PRICE_DETAILS_ERR :
            return {
                ...state, 
                flightPriceDetail : {
                    response : null,
                    loading : false,
                    error : action.output.message
                }
            }
            case REINITIALIZE_FLIGHT_PRICE_DETAILS_START :
            return {
                ...state, 
                flightPriceDetail : {
                    response : null,
                    loading  : false,
                    error : null
                }
         }
        //================================ For Filtering Results ===================================
        case ADVANCE_SEARCH_UPDATED :
            return {
                ...state, 
                advanceSearchFilter : action.advanceFilters
            }
        //================================ For Sorting Results ===================================
        case SORT_RESULTS :
            return {
                ...state, 
                resultSorting : action.resultSorting
            }
        default:
            return state;
    }
}


