import API from '..';

var LOGIN_API = {
    meta: {},
    jwt: '',
};

LOGIN_API.setToken = (token) => {
    LOGIN_API.jwt = token;
};

LOGIN_API.login = (credentials) => {
    return API.postResponse('/login', credentials, true);
};

LOGIN_API.generateToken = (credentials) => {
    return API.postResponse('/generate-token', credentials, true);
};

LOGIN_API.logout = () => {
    return API.logout();
};

export default LOGIN_API;