import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Download = ({ id, label, componentType, fileName, changeOverFlowClass }) => {
 
  if (componentType === 'hotel-voucher') {
 
    return (
      <div className="tc mb4 mt2">
        <div id="myMm" style={{ height: "1mm" }} />
        <div
          className="pa2 ba bw1 b--black bg-yellow black-90 br2 dib pointer dim shadow-1"
          onClick={() => {
            changeOverFlowClass('auto', () => {
              const input = document.getElementById(id);
              html2canvas(input, {
                scale: "1.5",
                // allowTaint : true,
                useCORS: true,
                 imageTimeout : 30000
              })
                .then((canvas) => {
                  const imgData = canvas.toDataURL('image/png');
                  let pdf;
                  pdf = new jsPDF('1', 'mm', 'letter',true);
                 
                  pdf.addImage(imgData, 'JPG', 8, 10, 200, 250);
                  let pdfName = fileName + '.pdf';
                  const savePDF = (pdfName, callback) => {
                    pdf.save(pdfName);
                    callback();
                  }
                  savePDF(pdfName, () => {
                    changeOverFlowClass('visible');
                  });
                });
            });
          }}
        >
          {label}
        </div>
      </div>
    )
  }
 
  else {

    return (
      <div className="tc mb4 mt2">
        <div id="myMm" style={{ height: "1mm" }} />
        <div
          className="pa2 ba bw1 b--black bg-yellow black-90 br2 dib pointer dim shadow-1"
          onClick={() => {
            changeOverFlowClass('auto', () => {
              const input = document.getElementById(id);
              html2canvas(input, {
                scale: 4, // Adjust scale for better quality
                useCORS: true,
                imageTimeout: 30000
              })
                .then((canvas) => {
                  const imgData = canvas.toDataURL('image/jpeg', 0.6); // Adjust compression
                  let pdf;
                  let data = localStorage.getItem('print-ticket-data');
                  pdf = new jsPDF('p', 'pt', 'letter', true);
                  let width = pdf.internal.pageSize.getWidth();
                  let height = pdf.internal.pageSize.getHeight();
                  pdf.addImage(imgData, 'JPEG', 0, 0, width, height, undefined, 'FAST'); // Use FAST for better compression
                  
                  let pdfName = 'Ticket-' + JSON.parse(data).pnr + '.pdf';
  
                  const compressAndSavePDF = (pdf, pdfName, callback) => {
                    const pdfBlob = pdf.output('blob');
                    if (pdfBlob.size <= 1024 * 1024) {
                 
                      pdf.save(pdfName);
                      callback();
                    } else {
                      // Adjust settings to ensure the PDF is always less than 1MB
                      pdf = new jsPDF('p', 'pt', 'letter', true);
                      pdf.addImage(imgData, 'JPEG', 0, 0, width, height, undefined, 'SLOW'); // Use SLOW for maximum compression
                      pdf.save(pdfName);
                      callback();
                    }
                  }
  
                  compressAndSavePDF(pdf, pdfName, () => {
                    changeOverFlowClass('visible');
                  });
                });
            });
          }}
        >
          {label}
        </div>
      </div>
    )
  }
  
  

}

export default Download;