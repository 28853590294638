
import {
    SESSION_LOGIN_START,
    SESSION_LOGIN_OK,
    SESSION_LOGIN_ERR,
    SESSION_LOGOUT,
    GENERATE_TOKEN_START,
    GENERATE_TOKEN_OK,
    GENERATE_TOKEN_ERR
} from '../../actions/shared/sessionAction';

export const initialSessionState = {
    token: null,
    error: null,
    loading: false
};


export function session(state = initialSessionState, action) {
    switch (action.type) {
        case SESSION_LOGIN_START:
            return Object.assign({}, state, {
                error: '',
                loading: true,
            });

        case SESSION_LOGIN_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                error: '',
                loading: false,
                token: action.token,
                ...action.decoded
            };

        case SESSION_LOGIN_ERR:
            /* reset session to initial state with the error */
            return Object.assign({}, initialSessionState, {
                error: action.error,
                loading: false,
            });

        case GENERATE_TOKEN_START:
            return Object.assign({}, state, {
                error: '',
                loading: true,
            });

        case GENERATE_TOKEN_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                error: '',
                loading: false,
                token: action.token,
                ...action.decoded
            };

        case GENERATE_TOKEN_ERR:
            /* reset session to initial state with the error */
            return Object.assign({}, initialSessionState, {
                error: action.error,
                loading: false,
            });
            
        case SESSION_LOGOUT:
            return initialSessionState;

        default:
            return state;
    }
}


